
<template>
  <div class="openBlind-Box">
    <div class="openBlind-Header">
      <div class="blindBoxTitle">
        <div class="title_text">
          <div>[</div>MYSTERY BOX
          <div>]</div>
        </div>
      </div>
      <div class="blindBoxDesc pc">What is in the Mystery Box this week?</div>
    </div>

    <div class="openBlind-content" v-if="!isOpen && !isAward">
      <div class="blindBoxs">
        <div class="blindBoxImage">
          <video
            class="video"
            id="gameVideo"
            loop="loop"
            ref="gameVideo"
            x5-playsinline="true"
            playsinline="true"
            x-webkit-airplay="true"
            webkit-playsinline="true"
            x5-video-player-type="h5"
            x5-video-orientation="portraint"
            style="object-fit:contain;border-radius: 5px;overflow: hidden;"
            data-setup="{}"
            preload="metadata"
            autoplay="true"
            muted
          >
            <source src="../assets/video/loop.mp4" type="video/mp4" />
          </video>
        </div>
        <div class="blindBoxInfo">
          <img src="../assets/images/shop/blindBox/infoBg.png" class="pc" alt />
          <div class="info_texts">
            <div class="countDownTip">Next claim in:</div>
            <div class="countDownContainer">
              <div class="iconBox">
                <div class="iconText_end">[</div>
                <div class="iconTip"></div>
              </div>
              <div class="timeBox">
                <div class="timeText notranslate">{{timeObj.day}}</div>
                <div class="timeTip">DAY[S]</div>
              </div>
              <div class="iconBox">
                <div class="iconText_center">:</div>
                <div class="iconTip"></div>
              </div>
              <div class="timeBox">
                <div class="timeText notranslate">{{timeObj.hour}}</div>
                <div class="timeTip">HOUR[S]</div>
              </div>
              <div class="iconBox">
                <div class="iconText_center">:</div>
                <div class="iconTip"></div>
              </div>
              <div class="timeBox">
                <div class="timeText notranslate">{{timeObj.minute}}</div>
                <div class="timeTip">MINUTE[S]</div>
              </div>
              <div class="iconBox">
                <div class="iconText_center">:</div>
                <div class="iconTip"></div>
              </div>
              <div class="timeBox">
                <div class="timeText notranslate">{{timeObj.second}}</div>
                <div class="timeTip">SECOND[S]</div>
              </div>
              <div class="iconBox">
                <div class="iconText_end">]</div>
                <div class="iconTip"></div>
              </div>
            </div>
            <div class="countDownTip pc">Amount:</div>
            <div class="myNumsBox pc">
              <img src="../assets/images/shop/blindBox/numsBg.png" alt />
              <div class="myNumsText">{{total}}</div>
            </div>
            <div class="amountBox mobile">
              <img src="../assets/images/shop/blindBox/amountBg.png" alt />
              <div class="amountBox-text">Amount:{{total}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="openBtnsBox">
        <div class="openBtnItem btnScale" @click="openBtn(1)">
          <img src="../assets/images/shop/shopImg/btnBg.png" alt />
          <div class="openBtnText">OPEN</div>
        </div>
        <div class="openBtnItem btnScale" @click="openBtn(2)">
          <img src="../assets/images/shop/shopImg/btnBg.png" alt />
          <div class="openBtnText">OPEN ALL</div>
        </div>
      </div>

      <div class="introduceBox">
        <img src="../assets/images/shop/blindBox/blindBoxInfo.png" alt />
        <div class="introduceText">
          <div class="introduceTitle">What is a [Mystery Box]?</div>
          <div class="introduceDesc">
            Each TORI will generate a weekly [Mystery Box], which may contain valuable assets.
            <br />The system will automatically adjust the drop rate in each mystery box based on the user's NFT activity index.the higher the index, the greater the mystery box rewards.
          </div>
        </div>
      </div>
    </div>
    <div class="openBlind-content" v-show="isOpen || isAward">
      <div class="blastBox" v-if="isOpen">
        <video
          class="video"
          id
          ref="gameVideo"
          x5-playsinline="true"
          playsinline="true"
          x-webkit-airplay="true"
          webkit-playsinline="true"
          x5-video-player-type="h5"
          x5-video-orientation="portraint"
          style="object-fit:contain;border-radius: 5px;overflow: hidden;"
          data-setup="{}"
          preload="metadata"
        >
          <source src="../assets/video/blast.mp4" type="video/mp4" />
        </video>
      </div>

      <div class="openBlind-prizeBox" v-if="isAward">
        <div class="openBlind-prizeBox-box" v-if="isAward">
          <div class="awardTitle">
            <img src="../assets/images/shop/blindBox/tips.png" alt />
          </div>
          <div :class="getList.length>3?'prizeScroll':'prizeListBox'">
            <div class="prizeItemBox" v-for="(item,index) in getList" :key="index">
              <img src="../assets/images/shop/property/assetBg.png" alt class="prizeItemBg" />
              <div class="prizeItemBoxImage">
                <img :src="item.picture" alt />
              </div>
              
              <div class="whitelistBox" v-if="item._type == 2">WHITELIST</div>
              <div class="whitelistBox" v-else-if="item._type == 1">num:{{item.amount}}</div>

              <div class="prizeItemname" v-if="item.name">{{item.name}}</div>

            </div>
          </div>

          <div class="mobile" :class=" getList.length>1?'swiperScroll':'swiperScroll_one'">
            <div class="prizeItemBox" v-for="(item,index) in getList" :key="index">
              <img src="../assets/images/shop/property/assetBg.png" alt class="prizeItemBg" />
              <div class="prizeItemBoxImage">
                <img :src="item.picture" alt />
              </div>
              <div class="whitelistBox" v-if="item._type == 2">WHITELIST</div>
              <div class="whitelistBox" v-else-if="item._type == 1">num:{{item.amount}}</div>
              <div class="prizeItemname" v-if="item.name">{{item.name}}</div>
            </div>
          </div>
          <div class="btnBox">
            <div class="btnBoxItem btnScale" @click="close()">
              <img src="../assets/images/shop/shopImg/btnBg.png" alt />
              <div class="btnBoxItemText">OK</div>
            </div>
            <div class="btnBoxItem btnScale" @click="goPrize()" v-if=" getList.length>1">
              <img src="../assets/images/shop/shopImg/btnBg.png" alt />
              <div class="btnBoxItemText">VIEW</div>
            </div>
          </div>
          <!--<div class="backBtn pc btnScale" @click="close()">
            <img src="../assets/images/shop/shopImg/btnBg.png" alt />
            <div class="backText">OK</div>
          </div>-->
          <!--<div
            v-if=" getList.length>1"
            class="tips"
          >Go to the [INVENTORY] to view the obtained items</div>-->
        </div>
      </div>
    </div>
  </div>
</template>


<script >
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      isOpen: false,
      isAward: false,
      total: 0,
      isGet: false,
      getList: [],
      timer: null,
      timeObj: {
        day: "??",
        hour: "??",
        minute: "??",
        second: "??"
      },
      startTime: null
    };
  },
  watch: {
    isConnect() {
      this.init();
    }
  },
  computed: {
    ...mapGetters(["web3", "account", "isConnect", "navIndex", 'globalConfig'])
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations(["SET_STATE", "SET_STATE_MORE"]),
    init () {
      if (!this.isConnect) return;
      this.initDownTime();
    },
    initDownTime() {
      clearInterval(this.timer);
      this.timer = null;
      this.getNextUserBlindBox(() => {
        this.startTime = this.utils.getWeek();
        this.setCountDownTime(this.startTime);
      });
    },
    playVideo() {
      this.$nextTick(() => {
        const video = this.$refs.gameVideo;
        const that = this;
        video.play();
        video.addEventListener(
          "ended",
          function() {
            //
            that.isAward = true;
            setTimeout(() => {
              that.isOpen = false;
            }, 500);
          },
          false
        );
      });
    },
    getNextUserBlindBox (callback) {
      this.$emit("getMyAccounts", () => {
        this.utils.post(
          "getUserBlindBoxNum",
          {
            address: this.account
          },
          res => {
            if (res.code == 0) {
              this.total = res.data;
              callback && callback();
            } else if (res.code == 1001) {
              this.SET_STATE_MORE([
                { key: "isRestart", value: true },
                { key: "tipShow", value: true },
                { key: "tipTitle", value: this.utils.tips.titleValError },
                { key: "tipSubtitle", value: this.utils.tips.tips1001 },
                { key: "btnText", value: this.utils.tips.btnText }
              ]);
            } else if (res.code == 1007) {
              this.SET_STATE_MORE([
                { key: "tipShow", value: true },
                { key: "tipFun", value: "goBindDiscord" },
                { key: "tipTitle", value: this.utils.tips.titleValError },
                { key: "tipSubtitle", value: this.utils.tips.tips1007 },
                { key: "btnText", value: this.utils.tips.btnText }
              ]);
            }
          }
        );
      });
    },
    setCountDownTime (val) {
      this.timer = setInterval(() => {
        this.timeObj = this.utils.getDate(val);
        if (this.navIndex != 1) {
          clearInterval(this.timer);
          this.timer = null;
        }
        if (!this.utils.contrastTime(this.startTime)) {
          this.initDownTime();
        }
      }, 1000);
    },
    openBtn (type) {
      this.$emit("getMyAccounts", () => {
        if (!this.isConnect) return;
        const {tips} = this.utils
        if (this.total > 0) {
          this.utils.post("proceedLottery", {
              address: this.account,
              type: type
            },
            res => {
              if (res.code == 0) {
                this.isOpen = true;
                this.playVideo();
                if (res.data && res.data.length > 0) {
                  this.getList = this.setList(res.data);
                }
              } else if (res.code == 1001) {
                this.SET_STATE_MORE([
                  { key: "isRestart", value: true },
                  { key: "tipShow", value: true },
                  { key: "tipTitle", value: tips.titleValError },
                  { key: "tipSubtitle", value: tips.tips1001 },
                  { key: "btnText", value: tips.btnText }
                ]);
              } else if (res.code == 1007) {
                this.SET_STATE_MORE([
                  { key: "tipShow", value: true },
                  { key: "tipFun", value: "goBindDiscord" },
                  { key: "tipTitle", value: tips.titleValError },
                  { key: "tipSubtitle", value: tips.tips1007 },
                  { key: "btnText", value: tips.btnText }
                ]);
              } else if (res.code == 1006) {
                this.SET_STATE_MORE([
                  { key: "tipShow", value: true },
                  { key: "tipTitle", value: tips.titleValError },
                  { key: "tipSubtitle", value: tips.tips1006 },
                  { key: "btnText", value: tips.btnText }
                ]);
              }
            }
          );
        } else {
          this.SET_STATE_MORE([
            { key: "tipShow", value: true },
            { key: "tipTitle", value: tips.titleValSorry },
            { key: "tipSubtitle", value: tips.InsufficientBlindBox },
            { key: "btnText", value: tips.btnText }
          ]);
        }
      });
    },
    setList(list) {
      let newList = [];
      for (let i = 0; i < list.length; i++) {
        const tempData = this.globalConfig[list[i].itemId]
        list[i] = {
          ...list[i],
          name: tempData.name,
          picture: `/static/images/headPortrait/${tempData.id}.png`,
          _type: tempData.type,
          twitter: tempData.twitter || ''
        }
        // list[i]["name"] = this.utils.getntfInfo("_name", list[i].itemId);
        // list[i]["picture"] = this.utils.getntfInfo("pic", list[i].itemId);

        // list[i]["_type"] = this.utils.getntfInfo("type", list[i].itemId);
        // list[i]["twitter"] = "";
        // if (this.utils.getntfInfo("twitter", list[i].itemId) != "") {
        //   list[i]["twitter"] = this.utils.getntfInfo("twitter", list[i].itemId);
        // }
        newList.push(list[i]);
      }
      return newList;
    },
    close () {
      this.isOpen = false;
      this.isAward = false;
      this.getNextUserBlindBox();
    },
    goPrize() {
      this.isOpen = false;
      this.isAward = false;
      this.$emit("setNowNav", 0);
    }
  }
};
</script>


<style lang="scss" scoped>
.openBlind {
  &-Box {
    width: 100%;
    height: 100%;
    padding: 2% 3%;
    box-sizing: border-box;
  }
  &-Header {
    width: 100%;
    height: 10%;
    position: relative;
    margin-bottom: 2%;
  }
  &-content {
    width: 100%;
    height: calc(100% - 90px);
    position: relative;
  }
  &-prizeBox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    &-box {
      width: 100%;
      height: 100%;
      padding: px2rem(34) 0 0 0;
      box-sizing: border-box;
      transition: 1s;
      transform: scale(0);
      animation: scaleKey 1s;
      animation-fill-mode: forwards;
    }
  }
}
@keyframes scaleKey {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.blastBox {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.blastBox video {
  width: 100%;
}
.blindBoxs {
  width: 100%;
  height: 42%;
  display: flex;
}
.blindBoxImage {
  width: 29%;
}
.blindBoxImage video {
  width: 100%;
}
.blindBoxImage img {
  width: 100%;
}
.blindBoxInfo {
  width: 71%;
  position: relative;
}
.blindBoxInfo img {
  width: 100%;
}
.info_texts {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3% 3%;
  box-sizing: border-box;
}
.countDownTip {
  width: 100%;
  color: #fff;
  font-size: px2rem(18);
  line-height: px2rem(24);
  margin-bottom: px2rem(12);
  font-family: Light;
}
.countDownContainer {
  width: 100%;
  display: flex;
  margin-top: 3%;
  margin-bottom: 3%;
}
.iconBox {
  width: px2rem(30);
}
.iconText_end {
  width: 100%;
  height: px2rem(50);
  line-height: px2rem(50);
  font-size: px2rem(28);
  text-align: center;
  color: #c6282e;
}

.iconText_center {
  width: 100%;
  height: px2rem(50);
  line-height: px2rem(50);
  font-size: px2rem(28);
  text-align: center;
  color: #fff;
  font-weight: bold;
}
.timeBox {
  width: px2rem(80);
}
.timeText {
  width: 100%;
  font-size: px2rem(32);
  height: px2rem(50);
  line-height: px2rem(50);
  font-weight: bold;
  font-family: Medium;
  color: #fff;
  text-align: center;
}
.timeTip {
  width: 100%;
  text-align: center;
  font-size: px2rem(13);
  color: #b22b2f;
  font-family: Regular;
}

.myNumsBox {
  width: 20%;
  position: relative;
}
.myNumsBox img {
  width: 100%;
}

.myNumsText {
  @extend .pos-flex-center;
  color: #fff;
  font-size: px2rem(18);
  font-family: Regular;
}

.openBtnsBox {
  width: 60%;
  margin: 0 auto;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: px2rem(76);
}

.openBtnItem {
  width: 38%;
  cursor: pointer;
  position: relative;
}
.openBtnItem img {
  width: 100%;
}
.openBtnText {
  font-size: px2rem(18);
  @extend .pos-flex-center;
  color: #fff;
  font-family: Regular;
}

.introduceBox {
  width: 100%;
  margin-top: px2rem(46);
  position: relative;
}
.introduceBox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.introduceText {
  position: relative;
  z-index: 2;
  padding: px2rem(12);
  box-sizing: border-box;
}

.introduceTitle {
  width: 100%;
  color: #fff;
  font-size: px2rem(18);
  line-height: px2rem(30);
}
.introduceDesc {
  width: 100%;
  font-size: px2rem(14);
  color: #b2b2b2;
  line-height: px2rem(26);
  margin-top: px2rem(8);
}

.blindBoxAnimation {
  width: 100%;
  height: 100%;
}
.blindBoxAnimation video {
  width: 100%;
  height: 100%;
}

.awardTitle {
  width: 50%;
  margin: 0 auto;
  margin-bottom: px2rem(14);
}
.awardTitle img {
  width: 100%;
  margin: 0 auto;
}
.prizeListBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.prizeListBox > div {
  margin-left: 2%;
  margin-right: 2%;
}
.prizeScroll {
  /*width: 100%;*/
  max-height: 60%;
  margin-top: px2rem(40);
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.prizeScroll::-webkit-scrollbar {
  width: px2rem(12);
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.prizeScroll::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  border-radius: 10px;
}
.prizeScroll::-webkit-scrollbar-corner {
  width: 0;
  display: none;
}
.prizeScroll:after {
  content: "";
  width: 24%;
}
.prizeScroll .prizeItemBox:last-child:nth-child(4n-2) {
  margin-right: 24%;
}

.prizeItemBox {
  width: 24%;
  margin-bottom: 2%;
  position: relative;
}
.prizeItemBg {
  width: 100%;
}
.prizeItemBoxImage {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.prizeItemBoxImage img {
  width: 100%;
}
.whitelistBox {
  width: 100%;
  position: absolute;
  bottom: 10%;
  text-align: center;
  color: #b2b2b2;
  font-size: px2rem(12);
}
.prizeItemname {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: px2rem(12);
  line-height: px2rem(20);
  position: absolute;
  left: 0;
  bottom: px2rem(0);
}

.backBtn {
  width: 26%;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 4%;
  position: relative;
}
.backBtn img {
  width: 100%;
}
.backText {
  @extend .pos-flex-center;
  font-size: px2rem(18);
  color: #fff;
}

.tips {
  width: 100%;
  text-align: center;
  line-height: px2rem(20);
  font-size: px2rem(16);
  color: #cccccc;
  margin-top: px2rem(24);
  font-family: Light;
}

.btnBox {
  width: 90%;
  margin: 0 auto;
  margin-top: px2rem(34);
  margin-bottom: px2rem(34);
  display: flex;
  justify-content: center;
}
.btnBoxItem {
  width: 24%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.btnBoxItem:first-of-type {
  margin-right: 3%;
}
.btnBoxItem:last-of-type {
  margin-left: 3%;
}
.btnBoxItem img {
  width: 100%;
}
.btnBoxItemText {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: px2rem(24);
  font-family: Regular;
}

@media screen and (max-width: 768px) {
  .openBlind-Box {
    padding: 0;
  }
  .prizeScroll,
  .prizeListBox {
    display: none;
  }
  .openBlind-Header {
    margin-bottom: 1%;
  }
  .blindBoxs {
    height: auto;
    display: block;
  }
  .blindBoxImage {
    width: 56%;
    margin: 0 auto;
  }
  .blindBoxInfo {
    width: 100%;
    margin-top: 2%;
  }
  .info_texts {
    width: 80%;
    margin: 0 auto;
    position: relative;
  }
  .countDownTip {
    text-align: center;
    font-size: px2rems(18);
    line-height: px2rems(24);
    margin-bottom: px2rems(12);
  }
  .awardTitle{
    width: 80%;
    margin-bottom: 4%;
  }
  .timeText {
    font-size: px2rems(32);
    height: px2rems(50);
    line-height: px2rems(50);
  }
  .iconText_end {
    height: px2rems(50);
    line-height: px2rems(50);
    font-size: px2rems(28);
  }

  .iconText_center {
    height: px2rems(50);
    line-height: px2rems(50);
    font-size: px2rems(28);
  }

  .iconBox {
    width: px2rem(50);
  }
  .iconText_end {
    width: 100%;
    height: px2rems(50);
    line-height: px2rems(50);
    font-size: px2rems(28);
    text-align: center;
    color: #c6282e;
  }

  .iconText_center {
    width: 100%;
    height: px2rems(50);
    line-height: px2rems(50);
    font-size: px2rems(28);
    text-align: center;
    color: #fff;
    font-weight: bold;
  }
  .timeBox {
    width: px2rems(100);
  }

  .amountBox {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: px2rems(30);
    img {
      width: 100%;
    }
    &-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: px2rems(30);
      font-family: Light;
    }
  }

  .openBtnsBox {
    width: 80%;
    margin: 0 auto;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
  }

  .openBtnItem {
    width: 38%;
    cursor: pointer;
    position: relative;
  }
  .openBtnItem img {
    width: 100%;
  }
  .openBtnText {
    font-size: px2rems(18);
    color: #fff;
    font-family: Regular;
  }

  .introduceBox {
    width: 100%;
    height: auto;
    margin-top: 4%;
    position: relative;
  }
  .introduceBox img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .introduceText {
    position: relative;
    padding: px2rems(12);
    box-sizing: border-box;
  }

  .introduceTitle {
    width: 100%;
    color: #fff;
    font-size: px2rems(22);
    line-height: px2rems(30);
  }
  .introduceDesc {
    width: 100%;
    font-size: px2rems(16);
    color: #b2b2b2;
    line-height: px2rems(26);
    margin-top: px2rems(8);
  }

  .swiperScroll {
    width: 100%;
    min-height: 70%;
    max-height: 82%;
    overflow-x: scroll;
  }

  .swiperScroll::-webkit-scrollbar {
    width: 0 !important;
  }

  .swiperScroll_one {
    width: 100%;
    height: 70%;
    position: relative;
  }
  .swiperScroll_one .prizeItemBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .prizeItemBox {
    width: 48%;
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;
  }
  .prizeinfo {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    margin-top: px2rems(26);
    font-size: px2rems(24);
    color: #fff;
    font-family: Regular;
  }

  .whitelistBox {
    bottom: 10%;
    color: #b2b2b2;
    font-size: px2rems(14);
  }
  .prizeItemname {
    color: #fff;
    font-size: px2rems(14);
    line-height: px2rems(28);
    bottom: px2rem(0);
  }

  .btnBox {
    width: 90%;
    margin: 0 auto;
    margin-top: px2rems(34);
    margin-bottom: px2rems(34);
  }
  .btnBoxItem {
    width: 46%;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .btnBoxItem:first-of-type {
    margin-right: 3%;
  }
  .btnBoxItem:last-of-type {
    margin-left: 3%;
  }
  .btnBoxItem img {
    width: 100%;
  }
  .btnBoxItemText {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: px2rems(34);
    font-family: Regular;
  }

  .tips {
    width: 100%;
    text-align: center;
    line-height: px2rems(20);
    font-size: px2rems(16);
    color: #cccccc;
    margin-top: px2rems(24);
    font-family: Light;
  }
}
</style>
