<template>
  <div class="shop-Container">
    <div class="BoxHeader">
      <div class="blindBoxTitle">
        <div class="title_text" style="width:auto">
          <div>[</div>MARKETPLACE
          <div>]</div>
        </div>
        <img src="../assets/images/shop/shopImg/refresh.png" alt @click="init()" class="btnScale" />
      </div>
      <div class="blindBoxDesc">Purchase items from the TORI marketplace with Fragments</div>
      <div class="capitalBox">
        <img src="../assets/images/shop/shopImg/capitalBg.png" alt />
        <div class="capitalText">{{integralVal}}</div>
      </div>
    </div>
    <img src="../assets/images/shop/shopImg/line.png" alt class="lineImg" />
    <div class="shop-content" v-if="loadIng && total>0" ref="scrollBox">
      <div class="shop-List" v-if="!isOpenDetail">
        <div
          class="prizesItem"
          v-for="(item,index) in prizesShowArr"
          :key="index"
          @click="openDetails(item,index)"
        >
          <img src="../assets/images/shop/property/assetBg.png" alt class="prizesItemBg" />
          <div class="prizesImage">
            <img :src="item.picture" alt />
          </div>
          <div class="whitelistBox" v-if="item.isWhitelist">WHITELIST</div>
          <div class="info">
            <div class="price">
              {{item.integralPrice}}
              <img
                src="../assets/images/shop/shopImg/priceIcon.png"
                alt
                class="priceIcon"
              />
            </div>
            <div class="nums">{{item.totalQuantity-item.nowConsumeNum}}/{{item.totalQuantity}}</div>
          </div>
          <div
            class="prizesName"
            v-if="item.name"
          >{{item.name.length>=5?item.name.substring(0,5)+'...':item.name}}</div>
        </div>
      </div>
      <!--  -->
      <div class="detailsBox" v-if="isOpenDetail">
        <div class="commodityBox">
          <div class="prizesItem" style="margin:0 auto;">
            <img src="../assets/images/shop/property/assetBg.png" alt class="prizesItemBg" />
            <div class="prizesImage">
              <img :src="detailsObj.picture" alt />
            </div>
            <div class="detailsWhitelist" v-if="detailsObj.isWhitelist">WHITELIST</div>
          </div>
          <div class="detailsName">{{detailsObj.name}}</div>
          <div class="detailsPrice" v-if="!isBuy">
            <div>Current balance: {{detailsObj.integralPrice}}</div>
            <img src="../assets/images/shop/shopImg/priceIcon.png" alt class="priceIcon" />
          </div>
          <div class="describe">{{detailsObj.description}}</div>
          <div class="describe" v-if="detailsObj.twitter && detailsObj.discord == ''">
            Twitter: 
            <a :href="detailsObj.twitter" target="_blank">{{detailsObj.twitter}}</a>
          </div>
          <div class="describe" v-if="detailsObj.discord">
            Discord: 
            <a :href="detailsObj.discord" target="_blank">{{detailsObj.discord}}</a>
          </div>

          
          <div class="describe" v-if="detailsObj.mintdate">Mint Date : {{detailsObj.mintdate}}</div>
          <div class="btnsBox" v-if="!isBuy">
            <div class="detailBtn btnScale" @click="backShop">
              <img src="../assets/images/shop/shopImg/btnBg.png" alt />
              <div class="detailText">CLOSE</div>
            </div>
            <div
              class="detailBtn btnScale"
              @click="buyBtnFun"
              v-show="detailsObj.nowConsumeNum < detailsObj.totalQuantity"
            >
              <img src="../assets/images/shop/shopImg/btnBg.png" alt />
              <div class="detailText">BUY</div>
            </div>
            <div
              class="detailBtn btnScale"
              @click="setTips('1004','')"
              v-show="detailsObj.nowConsumeNum >= detailsObj.totalQuantity"
            >
              <img src="../assets/images/shop/shopImg/tpBtn_noClick.png" alt />

              <div class="detailText">OUT OF STOCK</div>
            </div>
          </div>

          <div class="buySuccessBox" v-if="isBuy">
            <div class="buySuccessTip">Buy success!</div>
            <div class="backBtn btnScale" @click="backShop">
              <img src="../assets/images/shop/shopImg/btnBg.png" alt />
              <div class="detailText">CONFIRM</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="shop-noThings" v-if="loadIng && total<=0">
      <div class="shop-noThings-noThingTip">There are currently no items for sale</div>
    </div>
    <div class="pagingBox pc" v-if="!isOpenDetail && total>0">
      <div class="lastPage btnScale" @click="lastBtn">
        <img src="../assets/images/shop/property/prevBtn.png" alt />
      </div>
      <!--<div class="pageInput">
        <input
          type="text"
          onkeyup="this.value=this.value.replace(/\D/g, '')"
          v-model="page"
          @keyup.enter="convert_code_to_name"
          @blur="convert_code_to_name"
        />
      </div>-->
      <div class="pagingPages">{{page}}/{{pages}}</div>
      <div class="nextPage btnScale" @click="nextBtn">
        <img src="../assets/images/shop/property/nextBtn.png" alt />
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: 'ShopPage',
  data() {
    return {
      loadIng: false,
      integralVal: 0,
      prizesShowArr: [],
      pageSize: 0,
      page: 1,
      total: 0,
      pages: 0,
      isOpenDetail: false,
      detailsObj: {},
      isBuy: false,
      scrollBox: null,
      isScroll: false,
      isGet: true
    };
  },
  computed: {
    ...mapGetters(["web3", "account", "isConnect", "navIndex", 'globalConfig'])
  },
  watch: {
    account() {
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations(["SET_STATE", "SET_STATE_MORE"]),
    init () {
      this.page = 1;
      this.total = 0;
      this.prizesShowArr = [];
      this.isOpenDetail = false;
      this.getList();
      this.getIntegral();
    },
    scrollFun() {
      this.$nextTick(() => {
        const that = this;
        if (!this.$refs.scrollBox) return;
        this.$refs.scrollBox.addEventListener("scroll", () => {
          if (this.clientHeight + this.scrollTop >= this.scrollHeight - 50) {
            if (that.pageSize * that.page >= that.total) return;
            that.isScroll = true;
            if (!that.isGet) return;
            that.page += 1;
            that.getList();
          }
        });
      });
    },
    getList () {
      //let list = [
      //   { itemId: "142" },
      //   { itemId: "105" },
      //   { itemId: "103" },
      //   { itemId: "114" },
      //   { itemId: "107" },
      //   { itemId: "108" },
      //   { itemId: "120" }
      // ];

      // this.total = list.length;
      // this.pageSize = 8;
      // this.pages = 1;
      // this.setList(list);
      // this.loadIng = true;
      // return;
      if (!this.isGet) return;
      this.isGet = false;
      this.$emit("getMyAccounts", () => {
        this.SET_STATE({
          type: "loadIngShow",
          data: true
        });
        this.utils.post("getShopList", {
            address: this.account,
            pageNum: this.page,
            pageSize: this.pageSize
          },
          res => {
            this.isGet = true;
            if (this.navIndex == 2) {
              this.SET_STATE({
                type: "loadIngShow",
                data: false
              });
            }
            if (res.code == 0) {
              let resData = res.data;
              if (resData) {
                this.total = Number(resData.totalCount);
                this.pageSize = Number(resData.pageSize);
                this.pages = Math.ceil(this.total / this.pageSize);
                this.setList(resData.list);
              }
              this.loadIng = true;
              this.scrollFun();
            } else {
              this.setTips(res.code, "");
            }
          }
        );
      });
    },
    setList (list) {
      if (!this.isScroll) {
        this.prizesShowArr = [];
      }
      
      for (let i = 0; i < list.length; i++) {
        const tempData = this.globalConfig[list[i].itemId]
        list[i] = {
          ...list[i],
          name: tempData.name,
          description: tempData.description,
          picture: `/static/images/headPortrait/${tempData.id}.png`,
          isWhitelist: tempData.type === 2,
          twitter: tempData.twitter,
          discord: tempData.discord,
          mintdate: tempData.mintdate
        }
        // lisg[i]['name'] = this.globalConfig[list[i].itemId]?.name

        // list[i]["name"] = this.utils.getntfInfo("name", list[i].itemId);
        // list[i]["description"] = this.utils.getntfInfo(
        //   "description",
        //   list[i].itemId
        // );
        // list[i]["picture"] = this.utils.getntfInfo("pic", list[i].itemId);
        // list[i]["isWhitelist"] = false;
        // if (this.utils.getntfInfo("type", list[i].itemId) == "2") {
        //   list[i]["isWhitelist"] = true;
        // }
        // list[i]["twitter"] = this.utils.getntfInfo("twitter", list[i].itemId);
        // list[i]["discord"] = this.utils.getntfInfo("discord", list[i].itemId);

        // list[i]["mintdate"] = this.utils.getntfInfo("mintdate", list[i].itemId);
        this.prizesShowArr.push(list[i]);
        if (this.isOpenDetail) {
          if (list[i].id == this.detailsObj.id) {
            this.detailsObj = list[i];
          }
        }
      }
    },
    getIntegral () {
      this.$emit("getMyAccounts", () => {
        this.utils.post("getIntegral", {
            address: this.account
          },
          res => {
            if (res.code == 0) {
              this.integralVal = res.data;
            } else if (res.code == 1001) {
              this.SET_STATE({
                type: "isRestart",
                data: true
              });
              this.setTips(res.code, "");
            }
          }
        );
      });
    },
    convert_code_to_name () {
      this.getList();
    },
    lastBtn () {
      let page = Number(this.page);
      if (page <= 1) return;
      this.isScroll = false;
      page -= 1;
      this.page = page;
      this.getList();
    },
    nextBtn () {
      let page = Number(this.page);
      if (page >= this.pages) return;
      this.isScroll = false;
      page += 1;
      this.page = page;
      this.getList();
    },
    openDetails (item) {
      this.detailsObj = item;
      this.isOpenDetail = true;
    },
    buyBtnFun () {
      this.$emit("getMyAccounts", () => {
        this.utils.post(
          "exchangeProp",
          {
            address: this.account,
            propId: this.detailsObj.id
          },
          res => {
            if (res.code == 0) {
              this.isBuy = true;
              this.getIntegral();
            } else {
              if (res.code == 1007) {
                this.setTips(res.code, "goBindDiscord");
              } else {
                this.setTips(res.code, "");
              }
            }
          }
        );
      });
    },
    setTips(code, funVal) {
      let title = this.utils.tips.titleValError;
      if (code == 1005 || code == 1004) {
        title = this.utils.tips.titleValSorry;
      }
      this.SET_STATE_MORE([
        { key: "tipShow", value: true },
        { key: "tipFun", value: funVal },
        { key: "tipTitle", value: title },
        { key: "tipSubtitle", value: this.utils.tips["tips" + code] },
        { key: "btnText", value: this.utils.tips.btnText }
      ]);
    },
    backShop () {
      if (this.isBuy) this.getList();
      this.isOpenDetail = false;
      this.detailsObj = null;
      this.isBuy = false;
    }
  }
};
</script>


<style lang="scss" scoped>
.shop {
  &-Container {
    width: 100%;
    height: 100%;
    padding: 2% 3%;
    box-sizing: border-box;
    position: relative;
  }
  &-content {
    width: 100%;
    position: relative;
    margin-top: 4%;
  }
  &-List {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &-List:after {
    content: "";
    width: 21%;
  }

  &-noThings {
    width: 100%;
    height: calc(100% - 90px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &-noThingTip {
      width: auto;
      height: auto;
      color: #fff;
      font-size: px2rem(20);
      font-family: Light;
      span {
        cursor: pointer;
        color: red;
      }
    }
  }
}

.shop-List .prizesItem:last-child:nth-child(4n-2) {
  margin-right: 27%;
}
.lineImg {
  width: 100%;
  margin-top: 2%;
}
.capitalBox {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 13%;
  height: auto;
}
.capitalBox img {
  width: 100%;
}
.capitalText {
  width: 100%;
  text-align: center;
  padding-right: 28%;
  position: absolute;
  top: 52%;
  left: 0;
  transform: translateY(-50%);
  box-sizing: border-box;
  color: #fff;
  font-size: px2rem(16);
  font-family: Light;
}

.integral,
.currency {
  width: 80px;
  height: 30px;
  border: solid 1px #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  margin-right: 12px;
}

.moneyIcon {
  width: 20px;
  height: 20px;
}

.prizesItem {
  width: 21%;
  height: auto;
  margin-bottom: px2rem(12);
  position: relative;
  cursor: pointer;
}
.prizesItemBg {
  width: 100%;
}

.prizesImage {
  width: 80%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.prizesImage img {
  width: 100%;
}
.typeName {
  width: 90%;
  height: px2rem(30);
  text-align: right;
  font-size: px2rem(16);
  font-weight: medium;
  color: #6dc0f9;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: px2rem(68);
}
.info {
  width: 84%;
  height: px2rem(20);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5%;
  display: flex;
  justify-content: space-between;
}
.nums,
.price {
  flex: 1;
  text-align: right;
  line-height: px2rem(20);
  font-size: px2rem(12);
  color: #fff;
  font-family: Light;
}
.price {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.priceIcon {
  width: px2rem(16);
  height: auto;
  margin-left: px2rem(4);
}
.whitelistBox {
  width: 100%;
  text-align: center;
  color: #b2b2b2;
  font-size: px2rem(12);
  position: absolute;
  bottom: 10%;
}
.detailsName {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: px2rem(16);
  line-height: px2rem(16);
  font-family: Light;
  margin-top: 2%;
}

.detailsWhitelist {
  position: absolute;
  left: 0;
  bottom: 11%;
  width: 100%;
  text-align: center;
  color: #b2b2b2;
  font-size: px2rem(12);
}
.detailsPrice {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: px2rem(16);
  color: #fff;
  font-family: Light;
  margin-top: 2%;
}
.detailsPrice .priceIcon {
  width: px2rem(22);
  margin-left: 1%;
}

.prizesName {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: px2rem(16);
  line-height: px2rem(20);
  position: absolute;
  left: 0;
  bottom: 0;
  font-family: Light;
}

.pagingBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: px2rem(12);
}
.lastPage,
.nextPage {
  width: px2rem(16);
  cursor: pointer;
}
.lastPage img,
.nextPage img {
  width: 100%;
  height: auto;
}
.pageInput {
  width: px2rem(60);
  padding: 4px 0;
  text-align: center;
  background: rgba(61, 53, 94, 1);
  box-sizing: border-box;
}
.pageInput input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: #8b4931;
  box-sizing: border-box;
  background-color: rgba(46, 35, 69, 1);
  color: rgba(219, 199, 143, 1);
  text-align: center;
}
.pagingPages {
  width: px2rem(60);
  height: auto;
  text-align: center;
  color: #fff;
  font-size: px2rem(18);
}

.detailsBox {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: px2rem(18);
}
.commodityBox {
  width: 100%;
  height: auto;
}
.commodityBox .prizesItem {
  width: 23%;
}

.commodityDetail {
  width: 200px;
  height: auto;
  margin: 0 auto;
  margin-bottom: px2rem(30);
  position: relative;
  border: solid 1px #ccc;
  box-sizing: border-box;
}
.commodityImg {
  width: 100%;
  height: auto;
}
.commodityName {
  width: 100%;
  line-height: px2rem(20);
  text-align: center;
  position: absolute;
  bottom: 30px;
}
.commodityPrice {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #ccc;
  box-sizing: border-box;
}
.commodityPrice img {
  width: 20px;
  height: 20px;
}
.describe {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  line-height: px2rem(24);
  margin-top: 2%;
  color: #fff;
  font-size: px2rem(16);
  font-family: Light;
}
.describe a {
  color: #167fe2;
}

.btnsBox {
  @include pcwh(400, 80);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 1%;
}
.detailBtn,
.backBtn {
  width: 45%;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
}
.detailBtn img,
.backBtn img {
  width: 100%;
}
.detailText {
  width: 100%;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  font-size: px2rem(16);
}
.buySuccessBox {
  width: 50%;
  margin: 0 auto;
  margin-top: 2%;
}
.buySuccessTip {
  width: 100%;
  text-align: center;
  font-size: px2rem(34);
  font-weight: 700;
  line-height: px2rem(34);
  margin-bottom: 4%;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .shop-List .prizesItem:last-child:nth-child(4n-2) {
    margin-right: 0%;
  }
  .shop-Container {
    padding: 0;
    overflow: hidden;
  }
  .shop {
    &-noThings {
      width: 100%;
      height: calc(100% - 90px);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &-noThingTip {
        width: auto;
        height: auto;
        color: #fff;
        font-size: px2rems(20);
        font-family: Light;
        span {
          cursor: pointer;
          color: red;
        }
      }
    }
  }

  .capitalBox {
    width: 20%;
    top: 23%;
  }
  .capitalText {
    padding-left: px2rems(12);
    box-sizing: border-box;
    color: #fff;
    font-size: px2rems(16);
    font-family: Light;
  }
  .BoxHeader {
    height: 13%;
  }
  .shop-content {
    width: 86%;
    margin: 0 auto;
    margin-top: 2%;
    height: 84%;
    overflow: auto;
  }
  .shop-content::-webkit-scrollbar {
    width: 0 !important;
  }
  .shop-List {
    height: auto;
  }
  .prizesItem {
    width: 48%;
    margin-bottom: 4%;
  }

  .commodityBox .prizesItem {
    width: 45%;
  }

  .typeName {
    height: px2rems(30);
    font-size: px2rems(16);
    bottom: px2rems(80);
  }
  .info {
    height: px2rems(20);
    top: 6%;
  }
  .nums,
  .price {
    line-height: px2rems(20);
    font-size: px2rems(14);
  }
  .priceIcon {
    width: px2rems(20);
    height: auto;
    margin-left: px2rems(4);
  }

  .prizesName {
    font-size: px2rems(16);
    line-height: px2rems(26);
    bottom: px2rems(0);
  }
  .whitelistBox {
    font-size: px2rems(16);
  }

  .describe {
    width: 80%;
    margin: 0 auto;
    line-height: px2rems(40);
    margin-top: px2rems(16);
    margin-bottom: px2rems(12);
    color: #fff;
    font-size: px2rems(20);
    font-family: Light;
  }
  .detailsWhitelist {
    font-size: px2rems(12);
    bottom: 9%;
  }
  .detailsName {
    font-size: px2rems(28);
    line-height: px2rems(28);
    margin-top: px2rems(24);
  }
  .detailsPrice {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: px2rems(22);
    color: #fff;
    font-family: Light;
    margin-top: 4%;
    margin-bottom: 4%;
  }
  .detailsPrice .priceIcon {
    width: px2rems(30);
    margin-left: 2%;
  }

  .btnsBox {
    height: auto;
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .detailBtn,
  .backBtn {
    width: 40%;
    cursor: pointer;
    margin: 0 auto;
    position: relative;
    margin-bottom: px2rems(16);
  }
  .detailBtn img,
  .backBtn img {
    width: 100%;
  }
  .detailText {
    width: 100%;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #fff;
    font-size: px2rems(16);
  }
  .buySuccessBox {
    width: 100%;
  }
  .buySuccessTip {
    width: 100%;
    text-align: center;
    font-size: px2rems(38);
    font-weight: 700;
    line-height: px2rems(40);
    margin-bottom: px2rems(24);
    color: #fff;
  }
}
</style>