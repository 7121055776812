<template>
  <div class="genessis-Container">
    <div class="genessis-Container-title">
      <img src="../assets/images/shop/blindBox/title.png" alt />
      <div class="genessis-Container-title-text">GENESIS MYSTERY BOX</div>
    </div>
    <div class="genessis-Container-content" v-if="!isOpen && !isAward">
      <div class="genessis-Container-content-blindBox">
        <img src="../assets/images/shop/blindBox/blindBoxIcon.png" alt />
      </div>
      <div class="genessis-Container-content-own">YOU OWN:{{ownNum}}</div>
      <div class="genessis-Container-content-openBtn btnScale" @click="openFun()">
        <img src="../assets/images/shop/shopImg/btnBg.png" alt />
        <div class="genessis-Container-content-openBtn-text">OPEN</div>
      </div>
      <div
        class="genessis-Container-content-info"
      >Minting 4 Tori NFTs grants you a Mystery Box which may contain collab whitelists, tokens and REDLAB Fragments.</div>
    </div>
    <div class="genessis-Container-blastBox" v-if="isOpen">
      <video
        class="video"
        id
        ref="gameVideo"
        x5-playsinline="true"
        playsinline="true"
        x-webkit-airplay="true"
        webkit-playsinline="true"
        x5-video-player-type="h5"
        x5-video-orientation="portraint"
        style="object-fit:contain;border-radius: 5px;overflow: hidden;"
        data-setup="{}"
        preload="metadata"
      >
        <source src="../assets/video/blast.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="genessis-Container-prize" v-if="isAward">
      <div class="genessis-Container-prize-prizeBox">
        <div class="awardTitle pc">
          <img src="../assets/images/shop/blindBox/tips.png" alt />
        </div>
        <div :class="prizeArr.length>3?'prizeScroll':'prizeListBox'">
          <div class="prizeItemBox" v-for="(item,index) in prizeArr" :key="index">
            <img src="../assets/images/shop/property/assetBg.png" alt class="prizeItemBg" />
            <div class="prizeItemBoxImage">
              <img :src="item.picture" alt />
            </div>
            <div class="whitelistBox" v-if="item._type == 2">WHITELIST</div>
            <div class="whitelistBox" v-if="item._type == 1">num:{{item.amount}}</div>
            <div class="prizeItemname" v-if="item.name">{{item.name}}</div>
          </div>
        </div>

        <div class="mobile" :class=" prizeArr.length>1?'swiperScroll':'swiperScroll_one'">
          <div class="prizeItemBox" v-for="(item,index) in prizeArr" :key="index">
            <img src="../assets/images/shop/property/assetBg.png" alt class="prizeItemBg" />
            <div class="prizeItemBoxImage">
              <img :src="item.picture" alt />
            </div>
            <div class="whitelistBox" v-if="item._type == 2">WHITELIST</div>
            <div class="whitelistBox" v-if="item._type == 1">num:{{item.amount}}</div>
            <div class="prizeItemname" v-if="item.name">{{item.name}}</div>
          </div>
        </div>

        <div class="btnBox">
          <div class="btnBoxItem btnScale" @click="close()">
            <img src="../assets/images/shop/shopImg/btnBg.png" alt />
            <div class="btnBoxItemText">OK</div>
          </div>
          <div class="btnBoxItem btnScale" v-if="prizeArr.length>1" @click="goPrize()">
            <img src="../assets/images/shop/shopImg/btnBg.png" alt />
            <div class="btnBoxItemText">VIEW</div>
          </div>
        </div>
        <!--<div class="backBtn pc btnScale" @click="close()">
          <img src="../assets/images/shop/shopImg/btnBg.png" alt />
          <div class="backText">OK</div>
        </div>-->
        <!--<div class="tips">Go to [MY NFT] to view the details</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "GenesisBox",
  data() {
    return {
      ownNum: 0,
      openState: 0,
      isOpen: false,
      isAward: false,
      prizeArr: []
    };
  },
  watch: {
    isConnect() {
      this.init();
    }
  },
  computed: {
    ...mapGetters(["web3", "account", "isConnect", 'globalConfig'])
  },
  created() {
    this.getOnlyUserBlindBoxNum();
  },
  mounted() {},
  methods: {
    ...mapMutations(["SET_STATE", "SET_STATE_MORE"]),
    getOnlyUserBlindBoxNum() {
      this.$emit("getMyAccounts", () => {
        const {tips} = this.utils
        if (!this.account) return;
        this.utils.post(
          "getOnlyUserBlindBoxNum",
          {
            address: this.account
          },
          res => {
            if (res.code == 0) {
              if (res.data == 1) {
                this.ownNum = res.data;
                this.openState = res.data;
              } else {
                this.ownNum = 0;
                this.openState = res.data;
              }
            } else if (res.code == 1001) {
              this.SET_STATE_MORE([
                { key: "isRestart", value: true },
                { key: "tipShow", value: true },
                { key: "tipTitle", value: tips.titleValError },
                { key: "tipSubtitle", value: tips.tips1001 },
                { key: "btnText", value: tips.btnText }
              ]);
            } else if (res.code == 1007) {
              this.SET_STATE_MORE([
                { key: "tipShow", value: true },
                { key: "tipFun", value: "goBindDiscord" },
                { key: "tipTitle", value: tips.titleValError },
                { key: "tipSubtitle", value: tips.tips1007 },
                { key: "btnText", value: tips.btnText }
              ]);
            }
          }
        );
      });
    },
    playVideo() {
      this.$nextTick(() => {
        const video = this.$refs.gameVideo;
        const that = this;
        video.play();
        video.addEventListener(
          "ended",
          function() {
            //
            that.isAward = true;
            setTimeout(() => {
              that.isOpen = false;
            }, 1000);
          },
          false
        );
      });
    },
    openFun() {
      const {tips} = this.utils
      if (this.openState == 1) {
        this.utils.post("onlyProceedLottery", {
            address: this.account
          },
          res => {
            if (res.code == 0) {
              this.prizeArr = this.setList(res.data);
              this.isOpen = true;
              this.playVideo();
              this.getOnlyUserBlindBoxNum();
            } else if (res.code == 1001) {
              this.SET_STATE_MORE([
                { key: "isRestart", value: true },
                { key: "tipShow", value: true },
                { key: "tipTitle", value: tips.titleValError },
                { key: "tipSubtitle", value: tips.tips1001 },
                { key: "btnText", value: tips.btnText }
              ]);
            } else if (res.code == 1007) {
              this.SET_STATE_MORE([
                { key: "tipShow", value: true },
                { key: "tipFun", value: "goBindDiscord" },
                { key: "tipTitle", value: tips.titleValError },
                { key: "tipSubtitle", value: tips.tips1007 },
                { key: "btnText", value: tips.btnText }
              ]);
            }
          }
        );
      } else if (this.openState == 0) {
        this.SET_STATE_MORE([
          { key: "tipShow", value: true },
          { key: "tipTitle", value: tips.titleValSorry },
          { key: "tipSubtitle", value: tips.NoOpenQ },
          { key: "btnText", value: tips.btnText }
        ]);
      } else if (this.openState == 2) {
        this.SET_STATE_MORE([
          { key: "tipShow", value: true },
          { key: "tipTitle", value: tips.titleValSorry },
          { key: "tipSubtitle", value: tips.ended },
          { key: "btnText", value: tips.btnText }
        ]);
      }
    },
    setList(list) {
      let newList = [];
      for (let i = 0; i < list.length; i++) {
        const tempData = this.globalConfig[list[i].itemId] || {}
        list[i] = {
          ...list[i],
          name: tempData.name,
          picture: `/static/images/headPortrait/${list[i].itemId}.png`,
          _type: tempData.type
        }
        // list[i]["name"] = this.utils.getntfInfo("_name", list[i].itemId);
        // list[i]["picture"] = this.utils.getntfInfo("pic", list[i].itemId);

        // list[i]["_type"] = this.utils.getntfInfo("type", list[i].itemId);
        newList.push(list[i]);
      }
      return newList;
    },

    close: function() {
      this.isOpen = false;
      this.isAward = false;
    },
    goPrize() {
      this.$emit("setNowNav", 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.genessis {
  &-Container {
    width: 100%;
    height: 100%;
    padding: 2% 3%;
    box-sizing: border-box;
    color: #fff;
    &-title {
      width: 60%;
      height: 10%;
      position: relative;
      img {
        width: px2rem(526);
        height: px2rem(68);
        width: 100%;
      }
      &-text {
        width: px2rem(526);
        height: px2rem(68);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: px2rem(22);
        font-family: Medium;
        padding-left: px2rem(12);
      }
    }
    &-content {
      width: 100%;
      height: 86%;
      margin-top: 4%;
      position: relative;
      &-blindBox {
        width: 25%;
        height: auto;
        margin: 0 auto;
        margin-bottom: 6%;
        img {
          width: 100%;
        }
      }
      &-info {
        width: 85%;
        text-align: center;
        margin: 0 auto;
        font-size: px2rem(18);
        color: #b4b4b4;
        margin-top: 5%;
      }
      &-own {
        width: 100%;
        text-align: center;
        color: #fff;
        font-family: Medium;
        font-size: px2rem(20);
        margin-top: 2%;
        margin-bottom: 3%;
      }
      &-openBtn {
        width: 20%;
        position: relative;
        margin: 0 auto;
        cursor: pointer;
        img {
          width: 100%;
        }
        &-text {
          @extend .pos-flex-center;
          color: #fff;
          font-size: px2rem(18);
          font-family: Medium;
        }
      }
    }
    &-blastBox {
      width: 100%;
      height: 86%;
      position: relative;
      video {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &-prize {
      width: 100%;
      height: 86%;
      margin-top: 4%;
      position: absolute;
      top: 10%;
      left: 0;
      transform-origin: center center;
      transform: scale(0);
      animation: scaleKey 1s;
      animation-fill-mode: forwards;
      &-prizeBox {
        width: 94%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
@keyframes scaleKey {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.awardTitle {
  width: 50%;
  margin: 0 auto;
  margin-bottom: px2rem(14);
}
.awardTitle img {
  width: 100%;
  margin: 0 auto;
}
.prizeListBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.prizeListBox .prizeItemBox {
  margin-left: 2%;
  margin-right: 2%;
}
.prizeScroll {
  /*width: 100%;*/
  max-height: 60%;
  margin-top: px2rem(40);
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.prizeScroll:after {
  content: "";
  width: 24%;
}
.prizeScroll .prizeItemBox:last-child:nth-child(4n-2) {
  margin-right: 24%;
}
.prizeScroll::-webkit-scrollbar {
  width: px2rem(12);
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.prizeScroll::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  border-radius: 10px;
}
.prizeScroll::-webkit-scrollbar-corner {
  width: 0;
  display: none;
}
.prizeItemBox {
  width: 24%;
  margin-bottom: 2%;
}

.prizeItemBox {
  width: 24%;
  margin-bottom: 2%;
  position: relative;
}
.prizeItemBg {
  width: 100%;
}
.prizeItemBoxImage {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.prizeItemBoxImage img {
  width: 100%;
}
.whitelistBox {
  width: 100%;
  position: absolute;
  bottom: 10%;
  text-align: center;
  color: #b2b2b2;
  font-size: px2rem(12);
}
.prizeItemname {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: px2rem(12);
  line-height: px2rem(20);
  position: absolute;
  left: 0;
  bottom: px2rem(0);
}

.backBtn {
  width: 26%;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 4%;
  position: relative;
}
.backBtn img {
  width: 100%;
}
.backText {
  @extend .pos-flex-center;
  font-size: px2rem(18);
  color: #fff;
}

.tips {
  width: 100%;
  text-align: center;
  line-height: px2rem(20);
  font-size: px2rem(16);
  color: #cccccc;
  margin-top: px2rem(24);
  font-family: Light;
}

  .btnBox {
    width: 90%;
    margin: 0 auto;
    margin-top: px2rem(34);
    display: flex;
    justify-content: center;
    margin-bottom: px2rem(34);
  }
  .btnBoxItem {
    width: 26%;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .btnBoxItem:first-of-type {
    margin-right: 3%;
  }
  .btnBoxItem:last-of-type {
    margin-left: 3%;
  }
  .btnBoxItem img {
    width: 100%;
  }
  .btnBoxItemText {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: px2rem(18);
    font-family: Regular;
  }

@media screen and (max-width: 768px) {
  .prizeScroll,
  .prizeListBox {
    display: none;
  }
  .genessis {
    &-Container {
      width: 100%;
      height: 100%;
      padding: 0;
      box-sizing: border-box;
      color: #fff;
      &-title {
        width: 80%;
        position: relative;
        img {
          width: px2rems(526);
          height: px2rems(68);
        }
        &-text {
          width: px2rems(526);
          height: px2rems(68);
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          color: #fff;
          font-size: px2rems(22);
          font-family: Medium;
          padding-left: px2rems(12);
        }
      }
      &-content {
        width: 100%;
        &-blindBox {
          width: 50%;
          height: auto;
          margin: 0 auto;
          margin-top: 8%;
          margin-bottom: 8%;
          img {
            width: 100%;
          }
        }
        &-info {
          width: 85%;
          font-size: px2rems(18);
          color: #b4b4b4;
        }
        &-own {
          width: 100%;
          text-align: center;
          color: #fff;
          font-size: px2rems(20);
          margin-top: 6%;
          margin-bottom: 6%;
        }
        &-openBtn {
          width: 40%;
          position: relative;
          margin: 0 auto;
          img {
            width: 100%;
          }
          &-text {
            color: #fff;
            font-size: px2rems(24);
            font-family: Medium;
          }
        }
      }
    }
  }

  .swiperScroll {
    width: 100%;
    min-height: 70%;
    max-height: 82%;
    overflow-x: scroll;
  }
  .swiperScroll_one {
    width: 100%;
    height: 70%;
    position: relative;
  }
  .swiperScroll_one .prizeItemBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .swiperScroll::-webkit-scrollbar {
    width: 0 !important;
  }

  .prizeItemBox {
    width: 48%;
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;
  }
  .whitelistBox {
    bottom: 10%;
    color: #b2b2b2;
    font-size: px2rems(14);
  }
  .prizeItemname {
    color: #fff;
    font-size: px2rems(14);
    line-height: px2rems(28);
    bottom: px2rem(0);
  }

  .prizeinfo {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    margin-top: px2rems(26);
    font-size: px2rems(24);
    color: #fff;
    font-family: Regular;
  }

  .btnBox {
    width: 90%;
    margin: 0 auto;
    margin-top: px2rems(34);
    display: flex;
    justify-content: center;
    margin-bottom: px2rems(34);
  }
  .btnBoxItem {
    width: 46%;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .btnBoxItem:first-of-type {
    margin-right: 3%;
  }
  .btnBoxItem:last-of-type {
    margin-left: 3%;
  }
  .btnBoxItem img {
    width: 100%;
  }
  .btnBoxItemText {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: px2rems(34);
    font-family: Regular;
  }

  .tips {
    width: 100%;
    text-align: center;
    line-height: px2rems(20);
    font-size: px2rems(16);
    color: #cccccc;
    margin-top: px2rems(24);
    font-family: Light;
  }
}
</style>
