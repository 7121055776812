// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Vuex from 'vuex'
import router from './router'
import axios from "axios"
import Web3 from 'web3'
import store from './store/index.js'
import 'jquery'
import Utils from './common/utils.js';

Vue.use(Vuex)
Vue.prototype.Web3 = Web3
Vue.prototype.$http = axios;
Vue.prototype.utils = Utils;

Vue.config.productionTip = false
Vue.mixin({
  created() {
    this.getGlobalConfig()
  },
  methods: {
    // get global config
    getGlobalConfig() {
      this.utils.post('configItem/list', {}, (data) => {
        const result = this.utils.formatList2Obj(data.data, 'id')
        store.commit('SET_STATE', {
          type: 'globalConfig',
          data: result
        })
      })
    }
  }
})
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
