
//import Web3 from "web3";

import $ from 'jquery'
import Web3 from "web3";
import { config } from './config'
//console.log(config, 'utils config', post)
// const abi = require('./abi/abi');

const indexjs = {}

indexjs.tips = {
    titleValSuccess: 'correct',
    titleValError: 'Error',
    titleValSorry: "Sorry!",
    btnText: "OK",
    tips1001: 'The session is incorrect or expired, prompt the user to log in again',
    tips1002: 'Please select the item to buy',
    tips1003: 'The product does not exist',
    tips1004: 'Sold out',
    tips1005: 'Insufficient points, redemption failed',
    tips1006: 'The network is abnormal, please try again later',
    tips1007: 'Please connect DISCORD',
    tips1008: 'Please enter the correct little fox address',
    tips1011: 'The current DISCORD account is bound',
    NoOpenQ: 'Without the GEN-MYSTERY BOX, it cannot be opened',
    OpenQ: 'Eligible to open',
    ended: 'Has been opened',
    restTip: 'Successfully reset',
    updateInputNull: 'Please enter the wallet address to switch',
    authDiscord: "Please authorize discord first",
    updateInputSame: "The address is the same, no need to change",
    updateSuccess: "Whitelist Address set to ",
    InsufficientBlindBox: "Insufficient quantity of MYSTERY BOX",
    tipschainId: "Please change to Ethereum Chain.",
    signatureTips: "Signature failed, please refresh the page and try again",
    titleValBindSuccess: 'Good!',
    BindSucceeded: "Discord connected: ",
    UnboundSucceeded: "Discord disconnected",
    notMetamask: 'Please install metamask and refresh your browser'
}

indexjs.dialogueUrl = 'http://discord.gg/torizero1';

// const ntfInfoObj = {
//     '101': {
//         type: 1,
//         name: 'Data Fragmentation',
//         _name: 'Fragmentation',
//         pic: require('../assets/images/headPortrait/capitalBg.png'),
//         description: 'Items can be purchased on the Marketplace',
//         twitter: '',
//         discord: '',
//         startime: '',
//         endtime: '',
//         mintdate: '',
//     },
//     '102': {
//         type: 2,
//         name: 'Mint whitelist',
//         _name: 'Mint',
//         pic: require('../assets/images/headPortrait/unknown.png'),
//         description: 'Whitelist of Mystery Projects',
//         twitter: '',
//         discord: '',
//         startime: '',
//         endtime: '',
//         mintdate: '',
//     },
//     '103': {
//         type: 3,
//         name: 'Token OMO',
//         _name: 'Token OMO',
//         pic: require('../assets/images/headPortrait/unknown.png'),
//         description: 'Proof of obtaining the token of the mysterious project',
//         twitter: '',
//         discord: '',
//         startime: '',
//         endtime: '',
//         mintdate: '',
//     },
//     '104': {
//         type: 4,
//         name: 'NFT Eligibility',
//         _name: 'Eligibility',
//         pic: require('../assets/images/headPortrait/unknown.png'),
//         description: 'The exchange certificate of the mysterious project NFT',
//         twitter: '',
//         discord: '',
//         startime: '',
//         endtime: '',
//         mintdate: '',
//     },
//     '105': {
//         type: 2,
//         name: 'Avvenire WL',
//         _name: 'Avvenire',
//         pic: require('../assets/images/headPortrait/Avvenire.png'),
//         description: 'The whitelist will be automatically issued to the registered account within 48 hours',
//         twitter: 'https://twitter.com/AvvenireNFT',
//         discord: '',
//         startime: '2022-04-29 00:00:00',
//         endtime: '2022-05-05 00:00:00',
//         mintdate: '',
//     },
//     '106': {
//         type: 2,
//         name: 'REEF WL',
//         _name: 'REEF',
//         pic: require('../assets/images/headPortrait/ReefFishes.png'),
//         description: 'The whitelist will be automatically issued to the registered account within 48 hours',
//         twitter: 'https://twitter.com/ReefFishesNFT',
//         discord: '',
//         startime: '2022-04-29 00:00:00',
//         endtime: '2022-05-05 00:00:00',
//         mintdate: '',
//     },
//     '107': {
//         type: 2,
//         name: 'Brewies WL',
//         _name: 'Brewies',
//         pic: require('../assets/images/headPortrait/Brewies.png'),
//         description: 'The whitelist will be automatically issued to the registered account within 48 hours',
//         twitter: 'https://twitter.com/BrewiesNFT',
//         discord: '',
//         startime: '2022-04-29 00:00:00',
//         endtime: '2022-05-05 00:00:00',
//         mintdate: '',
//     },
//     '108': {
//         type: 2,
//         name: 'SENSHI WL',
//         _name: 'SENSHI',
//         pic: require('../assets/images/headPortrait/Senshii.png'),
//         description: 'The whitelist will be automatically issued to the registered account within 48 hours',
//         twitter: 'https://twitter.com/SenshiiNFT',
//         discord: '',
//         startime: '2022-04-29 00:00:00',
//         endtime: '2022-05-05 00:00:00',
//         mintdate: '',
//     },
//     '109': {
//         type: 2,
//         name: 'ALFIE WORLD WL',
//         _name: 'ALFIE',
//         pic: require('../assets/images/headPortrait/THEALFIEWORLD.png'),
//         description: 'The whitelist will be automatically issued to the registered account within 48 hours',
//         twitter: 'https://twitter.com/THEALFIEWORLD',
//         discord: '',
//         startime: '2022-04-29 00:00:00',
//         endtime: '2022-05-05 00:00:00',
//         mintdate: '',
//     },
//     '110': {
//         type: 2,
//         name: 'Ikusa WL',
//         _name: 'Ikusa',
//         pic: require('../assets/images/headPortrait/Ikusa.png'),
//         description: 'The whitelist will be automatically issued to the registered account within 48 hours',
//         twitter: 'https://twitter.com/IkusaNFT',
//         discord: '',
//         startime: '2022-04-29 00:00:00',
//         endtime: '2022-05-05 00:00:00',
//         mintdate: '',
//     },
//     '111': {
//         type: 2,
//         name: 'Alpha Gang WL',
//         _name: 'Alpha Gang',
//         pic: require('../assets/images/headPortrait/Alphagang.png'),
//         description: 'The whitelist will be automatically issued to the registered account within 48 hours',
//         twitter: 'https://twitter.com/AlphaGangX',
//         discord: '',
//         startime: '2022-04-29 00:00:00',
//         endtime: '2022-05-05 00:00:00',
//         mintdate: '',
//     },
//     '112': {
//         type: 2,
//         name: 'Neko Mura WL',
//         _name: 'Neko Mura',
//         pic: require('../assets/images/headPortrait/Nekomura.png'),
//         description: 'The whitelist will be automatically issued to the registered account within 48 hours',
//         twitter: 'https://twitter.com/nekomuraclub',
//         discord: '',
//         startime: '2022-04-29 00:00:00',
//         endtime: '2022-05-05 00:00:00',
//         mintdate: '',
//     },
//     '113': {
//         type: 2,
//         name: 'BoardGamers WL',
//         _name: 'BoardGamers',
//         pic: require('../assets/images/headPortrait/BoardGamers.png'),
//         description: 'The whitelist will be automatically issued to the registered account within 48 hours',
//         twitter: 'https://twitter.com/BoardGamersNFT',
//         discord: '',
//         startime: '2022-04-29 00:00:00',
//         endtime: '2022-05-05 00:00:00',
//         mintdate: '',
//     },
//     '114': {
//         type: 2,
//         name: 'Kosekai WL',
//         _name: 'Kosekai',
//         pic: require('../assets/images/headPortrait/Kosekai.png'),
//         description: 'The whitelist will be automatically issued to the registered account within 48 hours',
//         twitter: 'https://twitter.com/kosekai_NFT',
//         discord: '',
//         startime: '2022-04-29 00:00:00',
//         endtime: '2022-05-05 00:00:00',
//         mintdate: '',
//     },
//     '115': {
//         type: 2,
//         name: 'BobotsNFT WL',
//         _name: 'BobotsNFT',
//         pic: require('../assets/images/headPortrait/BobotsNFT.png'),
//         description: '@BobotsNFT,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/BobotsNFT',
//         discord: '',
//         startime: '2022-05-06 00:00:00',
//         endtime: '2022-05-13 00:00:00',
//         mintdate: 'May later',
//     },
//     '116': {
//         type: 2,
//         name: 'Thieves WL',
//         _name: 'Thieves',
//         pic: require('../assets/images/headPortrait/Thieves.png'),
//         description: '@ThievesNFTs,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/ThievesNFTs',
//         discord: '',
//         startime: '2022-05-06 00:00:00',
//         endtime: '2022-05-13 00:00:00',
//         mintdate: 'May later',
//     },
//     '117': {
//         type: 2,
//         name: 'ADolphinsNFT WL',
//         _name: 'ADolphinsNFT',
//         pic: require('../assets/images/headPortrait/ADolphinsNFT.png'),
//         description: '@ADolphinsNFT,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/ADolphinsNFT',
//         discord: '',
//         startime: '2022-05-06 00:00:00',
//         endtime: '2022-05-13 00:00:00',
//         mintdate: 'May later',
//     },
//     '118': {
//         type: 2,
//         name: 'REV3AL WL',
//         _name: 'REV3AL',
//         pic: require('../assets/images/headPortrait/REV3AL.png'),
//         description: '@REV3AL_Zone,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/REV3AL_Zone',
//         discord: '',
//         startime: '2022-05-06 00:00:00',
//         endtime: '2022-05-13 00:00:00',
//         mintdate: 'May mid',
//     },
//     '119': {
//         type: 2,
//         name: 'Boogie Doggies WL',
//         _name: 'Boogie Doggies',
//         pic: require('../assets/images/headPortrait/BoogieDoggies.png'),
//         description: '@BoogieDoggies,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/BoogieDoggies',
//         discord: '',
//         startime: '2022-05-06 00:00:00',
//         endtime: '2022-05-13 00:00:00',
//         mintdate: 'May mid',
//     },
//     '120': {
//         type: 2,
//         name: 'Tasties WL',
//         _name: 'Tasties',
//         pic: require('../assets/images/headPortrait/Tasties.png'),
//         description: '@TastiesNFT,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'https://discord.gg/tasties',
//         startime: '2022-05-06 00:00:00',
//         endtime: '2022-05-13 00:00:00',
//         mintdate: 'May mid',
//     },
//     '121': {
//         type: 2,
//         name: 'FlowerLolita WL',
//         _name: 'FlowerLolita',
//         pic: require('../assets/images/headPortrait/flowerlolitanft.png'),
//         description: '@flowerlolitanft,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'http://discord.gg/flowerlolita',
//         startime: '',
//         endtime: '2022-05-22 00:00:00',
//         mintdate: '22-May',
//     },
//     '122': {
//         type: 2,
//         name: 'CLSTsecrets WL',
//         _name: 'CLSTsecrets',
//         pic: require('../assets/images/headPortrait/CLSTsecrets.png'),
//         description: '@CLSTsecrets,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'https://discord.com/invite/Uqe4ntqEaK',
//         startime: '',
//         endtime: '2022-05-18 00:00:00',
//         mintdate: '18-May',
//     },
//     '123': {
//         type: 2,
//         name: 'Crazy Catz WL',
//         _name: 'Crazy Catz',
//         pic: require('../assets/images/headPortrait/crazycatznft.png'),
//         description: '@crazycatznft,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/crazycatznft',
//         discord: '',
//         startime: '',
//         endtime: '2022-05-31 00:00:00',
//         mintdate: '31-May',
//     },
//     '124': {
//         type: 2,
//         name: 'Childhood WL',
//         _name: 'Childhood',
//         pic: require('../assets/images/headPortrait/Childhood_NFT.png'),
//         description: '@Childhood_NFT,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'https://discord.com/invite/childhooddreams',
//         startime: '',
//         endtime: '',
//         mintdate: 'May later',
//     },
//     '125': {
//         type: 2,
//         name: 'LaidBackLlamas WL',
//         _name: 'LaidBackLlamas',
//         pic: require('../assets/images/headPortrait/LaidBackLlamas.png'),
//         description: '@LaidBackLlamas,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/laidbackllamas',
//         discord: '',
//         startime: '',
//         endtime: '2022-05-16 00:00:00',
//         mintdate: '16-May',
//     },
//     '126': {
//         type: 2,
//         name: 'Great Goats WL',
//         _name: 'Great Goats',
//         pic: require('../assets/images/headPortrait/Great.png'),
//         description: '@GreatGoatsNFT,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'https://greatgoats.io/',
//         startime: '',
//         endtime: '2022-06-03 00:00:00',
//         mintdate: 'May later',
//     },
//     '127': {
//         type: 2,
//         name: 'Innate Fervo WL',
//         _name: 'Innate Fervo',
//         pic: require('../assets/images/headPortrait/Innate.png'),
//         description: '@InnateFervor,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'https://discord.gg/innatefervor',
//         startime: '',
//         endtime: '2022-06-03 00:00:00',
//         mintdate: 'May later',
//     },
//     '128': {
//         type: 2,
//         name: 'WineWayNFT WL',
//         _name: 'WineWayNFT',
//         pic: require('../assets/images/headPortrait/Wine.png'),
//         description: '@WineWayNFT,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/WineWayNFT',
//         discord: '',
//         startime: '',
//         endtime: '2022-06-03 00:00:00',
//         mintdate: 'May later',
//     },
//     '129': {
//         type: 2,
//         name: 'Dtools WL',
//         _name: 'Dtools',
//         pic: require('../assets/images/headPortrait/Dtools.png'),
//         description: '@DtoolsTeam,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'https://discord.gg/EgabUwWWMV',
//         startime: '',
//         endtime: '2022-06-03 00:00:00',
//         mintdate: 'May later',
//     },
//     '130': {
//         type: 2,
//         name: 'YokaiVerseIO WL',
//         _name: 'YokaiVerseIO',
//         pic: require('../assets/images/headPortrait/Yokai.png'),
//         description: '@YokaiVerseIO,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'https://discord.gg/yokaiverse',
//         startime: '',
//         endtime: '2022-06-03 00:00:00',
//         mintdate: 'May later',
//     },
//     '131': {
//         type: 2,
//         name: 'Derpy Friends WL',
//         _name: 'Derpy',
//         pic: require('../assets/images/headPortrait/Derpy.png'),
//         description: '@DerpyFriends,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/DerpyFriends',
//         discord: '',
//         startime: '',
//         endtime: '2022-06-10 00:00:00',
//         mintdate: '',
//     },
//     '132': {
//         type: 2,
//         name: 'Melaxy WL',
//         _name: 'Melaxy',
//         pic: require('../assets/images/headPortrait/Melaxy.png'),
//         description: '@MelaxyGame,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'https://discord.gg/melaxy',
//         startime: '',
//         endtime: '2022-06-10 00:00:00',
//         mintdate: '',
//     },
//     '133': {
//         type: 2,
//         name: 'Ex Paradiso WL',
//         _name: 'Ex Paradiso',
//         pic: require('../assets/images/headPortrait/Paradiso.png'),
//         description: '@ExParadiso,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/exparadiso',
//         discord: '',
//         startime: '',
//         endtime: '2022-06-10 00:00:00',
//         mintdate: '',
//     },
//     '134': {
//         type: 2,
//         name: 'Drippy Zombies WL',
//         _name: 'Drippy',
//         pic: require('../assets/images/headPortrait/Drippy.png'),
//         description: '@drippyzombies,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/drippyzombies',
//         discord: '',
//         startime: '',
//         endtime: '2022-06-10 00:00:00',
//         mintdate: '',
//     },
//     '135': {
//         type: 2,
//         name: 'WeirdNomadClub WL',
//         _name: 'WeirdNomadClub',
//         pic: require('../assets/images/headPortrait/WeirdNomadClub.png'),
//         description: '@WeirdNomadClub,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'https://discord.gg/wnc',
//         startime: '',
//         endtime: '2022-06-10 00:00:00',
//         mintdate: '',
//     },
//     '136': {
//         type: 2,
//         name: 'IDENTITIES WL',
//         _name: 'IDENTITIES',
//         pic: require('../assets/images/headPortrait/6101.png'),
//         description: '@IDNTTS,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/IDNTTS',
//         discord: '',
//         startime: '',
//         endtime: '2022-06-17 00:00:00',
//         mintdate: '',
//     },
//     '137': {
//         type: 2,
//         name: 'Hawa Origin WL',
//         _name: 'Hawa Origin',
//         pic: require('../assets/images/headPortrait/6102.png'),
//         description: '@HawaOrigin,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'https://discord.gg/hawaorigin',
//         startime: '',
//         endtime: '2022-06-17 00:00:00',
//         mintdate: '',
//     },
//     '138': {
//         type: 2,
//         name: 'MONONOKE NFT',
//         _name: 'MONONOKE',
//         pic: require('../assets/images/headPortrait/MONONOKENFT.png'),
//         description: '@MONONOKE NFT,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'https://discord.gg/mononoke',
//         startime: '',
//         endtime: '2022-06-24 00:00:00',
//         mintdate: '',
//     },
//     '139': {
//         type: 2,
//         name: 'Baby Satoshi',
//         _name: 'Baby Satoshi',
//         pic: require('../assets/images/headPortrait/BabySatoshi.png'),
//         description: '@Baby Satoshi,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/SatoshiRunnerz',
//         discord: '',
//         startime: '',
//         endtime: '2022-06-24 00:00:00',
//         mintdate: '',
//     },
//     '140': {
//         type: 2,
//         name: 'FlippinRabbits',
//         _name: 'FlippinRabbits',
//         pic: require('../assets/images/headPortrait/FlippinRabbits.png'),
//         description: '@FlippinRabbits,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: 'https://twitter.com/FlippinRabbits',
//         discord: '',
//         startime: '',
//         endtime: '2022-06-24 00:00:00',
//         mintdate: '',
//     },
//     '141': {
//         type: 2,
//         name: 'matthewrobotnft',
//         _name: 'matthewrobotnft',
//         pic: require('../assets/images/headPortrait/matthewrobotnft.png'),
//         description: '@matthewrobotnft,your wallet will be added to the contract directly. You do not need to claim the roles in their Discord but we still highly encourage you to join the Discord for daily update and developments.',
//         twitter: '',
//         discord: 'http://discord.gg/xNH3nq96Dx',
//         startime: '',
//         endtime: '2022-06-24 00:00:00',
//         mintdate: '',
//     },
//     '142': {
//         type: 4,
//         name: 'BWL & Tori Zero',
//         _name: 'BWL',
//         pic: require('../assets/images/headPortrait/BWLToriZero.png'),
//         description: 'Please take a screenshot of whole window and create a ticket on our server. ',
//         twitter: '',
//         discord: '',
//         startime: '',
//         endtime: '',
//         mintdate: '',
//     },
//     '143':{
//         type:4,
//         name:"Tori Land",
//         _name:"Tori Land",
//         pic: 'Tori Land',
//         description:"Please create a ticket on our discord after your puchasing",
//         twitter:"",
//         discord:"",
//         startime:"",
//         endtime:"2022-07-15 00:00:00",
//         mintdate:""
//     }
// }

// indexjs.getntfInfo = function (objKey, itemId) {
//     console.log(itemId, '------')
//     return ntfInfoObj[itemId][objKey]
// }



indexjs.connectContract = async function (callback) {
    var web3Provider;
    let func = async () => {
        if (this.isConnect) {
            indexjs.connectContract();
        }
    };
    if (window.ethereum) {
        window.ethereum.autoRefreshOnNetworkChange = false;
        window.ethereum.on("accountsChanged", func);
        window.ethereum.on("chainChanged", chainId1 => {
            this.chainId = chainId1;
            func([this.account]);
        });
        web3Provider = window.ethereum;
        try {
            await window.ethereum.enable();
        } catch (error) {
            console.error("User denied account access");
        }
    } else if (window.Web3) {
        web3Provider = window.Web3.currentProvider;
    } else {
        web3Provider = new Web3.providers.HttpProvider(
            config.RPC_URL
        );
    }
    callback(web3Provider)

};
const mainurl = config.mainUrl;
var isSend = false;
var nowUrl = '';
indexjs.login = function (url, data, success, errFn) {
    if (isSend) return
    isSend = true;
    data = JSON.stringify(data);
    $.ajax({
        url: mainurl + url,
        type: 'post',
        data: data,
        headers: {
        },
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true,

        contentType: 'application/json',
        success: function (data) {
            isSend = false;
            success(data)
            if (data.code != 0) {
                //setTips(data.code)
            }
        },
        error: function (err) {
            isSend = false;
            errFn && errFn(err)
        }
    })
}
indexjs.post = function (url, data, success, errFn) {
    if (url == nowUrl && isSend) return
    nowUrl = url
    isSend = true;
    // var str = "";
    data['sessionId'] = sessionStorage.getItem('mySession');
    data = JSON.stringify(data);
    $.ajax({
        url: mainurl + url,
        type: 'post',
        data: data,
        headers: {
        },
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true,

        contentType: 'application/json',
        success: function (data) {
            isSend = false;
            nowUrl = '';
            success(data)
            if (data.code != 0) {
                //setTips(data.code)
            }
        },
        error: function (err) {
            isSend = false;
            errFn && errFn(err)
        }
    })
}

indexjs.formatList2Obj = function (list, keyItem) {
    const obj = {}
    list.forEach(item => {
        obj[item[keyItem]] = item
    })
    return obj
}

indexjs.getCashed = function (endStr) {
    // const startTime = new Date(startStr.replace(/-/g, '/')).getTime();
    const endTime = new Date(endStr.replace(/-/g, '/')).getTime();
    const noeTime = getNowUTC();

    return noeTime <= endTime ? 0 : 1;
}


indexjs.getWeek = function () {
    let year, month, day;
    let week = new Date(getNowUTC()).getDay();	//d=0~6(0代表星期天)。
    var date = new Date(getNowUTC());
    const startStr = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(tow).join('-') + ' 12:00:00'
    // const startStr = tow(date.getFullYear()) + '-' + tow(date.getMonth() + 1) + '-' + tow(date.getDate()) + ' ' + '12' + ':' + '00' + ':' + '00'

    var startTime = new Date(startStr.replace(/-/g, '/')).getTime();

    var noeTime = getNowUTC();
    var isStart = noeTime < startTime;


    if (week == 0) {//7
        date.setDate(date.getDate() + (5 - date.getDay()));
    } else if (week > 5) {//6
        date.setDate(date.getDate() + (12 - date.getDay()));
    } else if (week == 5 && !isStart) {//5
        date.setDate(date.getDate() + (12 - date.getDay()));
    } else {// 1 2 3 4
        date.setDate(date.getDate() + (5 - week));
    }

    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate()
    let str = [year, month, day].map(tow).join('-') + ' 12:00:00'
    // let str = tow(year) + '-' + tow(month) + '-' + tow(day) + ' ' + '12' + ':' + '00' + ':' + '00'
    //  startTime = new Date(str).getTime()

    startTime = new Date(str.replace(/-/g, '/')).getTime()

    return startTime

}

function getNowUTC() {
    return new Date(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        new Date().getUTCSeconds()
    ).getTime();
}
indexjs.getDate = function (startTime) {
    var nowTime = getNowUTC();
    var second = Math.floor((startTime - nowTime) / 1000);
    var day = Math.floor(second / 86400);
    second = second % 86400;
    var hour = Math.floor(second / 3600);
    second %= 3600;
    var minute = Math.floor(second / 60);
    second %= 60;

    let timeObj = {
        day: tow(day),
        hour: tow(hour),
        minute: tow(minute),
        second: tow(second),
    }
    return timeObj
};

function tow(n) {
    return String(n).padStart(2, '0')
    // return n >= 0 && n < 10 ? "0" + n : "" + n;
}

indexjs.contrastTime = function (startTime) {
    var isStart = false;
    var noeTime = getNowUTC();
    if (noeTime < startTime) {
        isStart = true
    }
    return isStart
}

export default indexjs;
