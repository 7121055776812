const USE_PRODUCTION_ENV = false
const prod = 'production'
const dev = 'development'

const baseConfig = {
  // development
  [dev]: {
    RPC_URL: 'https://rinkeby.etherscan.io/',
    mainUrl: 'https://servertest.torizero.com/',
    
    chainIdConfig: 4, // 4
    chainName: 'test tori',
  },
  // production
  [prod]: {
    RPC_URL: 'https://mainnet.infura.io/v3/',
    mainUrl: 'https://server.torizero.com/',
    chainIdConfig: 1, // 4
    chainName: 'prod tori',
  }
}

export const config = USE_PRODUCTION_ENV ? baseConfig[process.env.NODE_ENV] : baseConfig[dev]
