<template>
  <div class="homePage-container">
    <!--<img src="../assets/images/shop/bg.png" alt class="homePage-container-bg" />-->
    <loginBox @connectContract="connectContract" @loginFun="loginFun" v-if="!isLogin"></loginBox>
    <tipBox @setNowNav="setNowNav" @clearFun="clearFun" @switchChain="switchChain" v-if="!isLogin"></tipBox>
    <div class="mobileNavBox mobile" v-if="isLogin">
      <mobileNav v-if="isLogin" @setNowNav="setNowNav"></mobileNav>
    </div>
    <div class="homePage-TableBox" v-if="isLogin">
      <div class="homePage-TableBox-Header pc">
        <div class="homePage-TableBox-Header-logo" @click="backHome()">
          <img src="../assets/images/shop/logo.png" alt />
        </div>
        <div class="homePage-TableBox-Header-connectBox">
          <img src="../assets/images/shop/wallet.png" alt />
          <div
            class="homePage-TableBox-Header-connectBox-connectText"
            v-if="!isConnect"
            @click="connectContract()"
          >Administration</div>
          <div
            class="homePage-TableBox-Header-connectBox-connectText"
            v-if="isConnect && account"
          >{{account.substring(0,5)+'...'+account.substring(account.length,account.length-4)}}</div>
        </div>
      </div>

      <div class="homePage-TableBox-table">
        <img src="../assets/images/shop/tableBg.png" alt class="pc" />
        <div class="homePage-TableBox-table-leftNavBox pc">
          <div
            class="homePage-TableBox-table-leftNavBox-navItem btnScale"
            :class="navIndex==index?'navItemActive':''"
            v-for="(item,index) in navList"
            :key="index"
            @click="setNowNav(index)"
          >
            <img :src="navIndex == index?item.imgActive:item.img" alt />
          </div>
          <div class="homePage-TableBox-table-leftNavBox-navItem btnScale">
            <a
              href="https://mirror.xyz/torizero-redlab.eth"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="../assets/images/header/pc_nav/faq.png" alt />
            </a>
          </div>
          <div class="dialogue btnScale" @click="goDialogue">
            <img src="../assets/images/header/dialogue.png" alt />
          </div>
        </div>
        <div class="homePage-TableBox-table-rightContent">
          <tipBox
            @setNowNav="setNowNav"
            @clearFun="clearFun"
            @switchChain="switchChain"
            v-if="isLogin"
          ></tipBox>
          <prizes v-if="navIndex==0" @setNowNav="setNowNav" @getMyAccounts="getMyAccounts"></prizes>
          <open-blind-box
            v-else-if="navIndex==1"
            @setNowNav="setNowNav"
            @getMyAccounts="getMyAccounts"
          ></open-blind-box>
          <shop v-else-if="navIndex==2" @getMyAccounts="getMyAccounts"></shop>
          <account-link v-else-if="navIndex==3" @getMyAccounts="getMyAccounts"></account-link>
          <genesis-box
            v-else-if="navIndex==4"
            @setNowNav="setNowNav"
            @getMyAccounts="getMyAccounts"
          ></genesis-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
import { mapGetters, mapMutations } from "vuex";
import loginBox from "../components/login";
import mobileNav from "../components/mobileNav";
import tipBox from "../components/tips";
import openBlindBox from "../components/openBlindBox";
import prizes from "../components/prizes";
import shop from "../components/shop";
import accountLink from "../components/accountLink";
import genesisBox from "../components/genesisBox";
import { config } from "@/common/config";
export default {
  computed: {
    ...mapGetters(["web3", "account", "isConnect", "chainIdVal"]),
    navList() {
      // if user nft > 4 show
      return this.nav;
      // if (this.account) {
      //   return this.nav
      // }
      // //
      // return this.nav.filter((item, i) => i < this.nav.length - 1)
    }
  },
  components: {
    mobileNav,
    loginBox,
    prizes,
    openBlindBox,
    shop,
    accountLink,
    genesisBox,
    tipBox
  },
  data() {
    return {
      isLogin: false,
      isEnter: true,
      nav: [
        {
          img: require("../assets/images/header/pc_nav/prizes.png"),
          imgActive: require("../assets/images/header/pc_nav/prizesActive.png")
        },
        {
          img: require("../assets/images/header/pc_nav/blindBox.png"),
          imgActive: require("../assets/images/header/pc_nav/bbActive.png")
        },
        {
          img: require("../assets/images/header/pc_nav/shop.png"),
          imgActive: require("../assets/images/header/pc_nav/shopActive.png")
        },
        {
          img: require("../assets/images/header/pc_nav/al.png"),
          imgActive: require("../assets/images/header/pc_nav/alActive.png")
        },
        // {
        //   img: require("../assets/images/header/pc_nav/luck.png"),
        //   imgActive: require("../assets/images/header/pc_nav/luckActive.png")
        // }
      ],
      nonce: "Sign this message to login account link.",
      navIndex: 0
    };
  },
  created() {
    if (this.$route.query.Enter) {
      this.isEnter = false;
    }

    this.connectContract();
  },
  mounted() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        this.clearFun();
        this.connectContract();
      });
      window.ethereum.on("chainChanged", async chainId1 => {
        this.SET_STATE({
          type: "chainIdVal",
          data: chainId1
        });
        if (this.checkAndSwitchChainId()) return;
        this.connectContract();
      });
    }
  },
  methods: {
    ...mapMutations([
      "savenavIndex",
      "saveAccount",
      "saveIsConnect",
      "saveweb3",
      "SET_STATE",
      "SET_STATE_MORE"
    ]),
    goDialogue: function() {
      window.open(this.utils.dialogueUrl, "_blank");
    },
    clearFun() {
      this.isLogin = false;
      this.setAccountConnectStatus("", null, false);
      sessionStorage.setItem("mySession", "");
      this.nowsignature = null;
      this.$router.push({ query: {} });
    },
    setNowNav(index) {
      if (index != 0 || index != 2) {
        this.SET_STATE({
          type: "loadIngShow",
          data: false
        });
      }
      this.getMyAccounts(() => {
        this.navIndex = index;

        this.savenavIndex(index);
      });
    },
    loginFun () {
      //if (!this.nowsignature) return;
      //sessionStorage.setItem("mySession", "test");
      //this.isLogin = true;
      //if (this.$route.query.code) {
      //  this.navIndex = 3;
      //}
      //this.SET_STATE({
      //  type: "isConnect",
      //  data: true
      //});
      //this.$router.push({ query: {} });
      this.getMyAccounts(() => {
        // var timestamp = Date.parse(new Date());
        this.utils.login(
          "signatureAuthentication",
          {
            address: this.account
          },
          res => {
            if (res.code == 0) {
              sessionStorage.setItem("mySession", res.sessionId);
              this.isLogin = true;

              if (this.$route.query.code) {
                this.navIndex = 3;
              }

              this.SET_STATE({
                type: "isConnect",
                data: true
              });
            } else if (res.code == 1000) {
              this.clearFun();
              this.SET_STATE_MORE([
                { key: "tipShow", value: true },
                { key: "tipTitle", value: this.utils.tips.titleValError },
                { key: "tipSubtitle", value: this.utils.tips.signatureTips },
                { key: "btnText", value: this.utils.tips.btnText }
              ]);
            }
          }
        );

        this.$router.push({ query: {} });
      });
    },
    backHome () {
      window.open("https://www.torizero.com/", "_self");
    },
    setAccountConnectStatus(account, web3, connectStatus) {
      this.saveAccount(account);
      this.saveweb3(web3);
      this.saveIsConnect(connectStatus);
    },
    async connectContract() {
      var web3Provider;
      var web3;

      if (!window.ethereum) {
        this.SET_STATE_MORE([
          { key: "tipShow", value: true },
          { key: "tipFun", value: "goBindDiscord" },
          { key: "tipTitle", value: this.utils.tips.titleValError },
          { key: "tipSubtitle", value: this.utils.tips.notMetamask },
          { key: "btnText", value: this.utils.tips.btnText }
        ]);
      }
      if (window.ethereum) {
        web3Provider = window.ethereum;
        window.ethereum.autoRefreshOnNetworkChange = false;
        try {
          await window.ethereum.enable();
        } catch (error) {
          console.log("User denied account access");
        }
      } else if (window.Web3) {
        web3Provider = window.Web3.currentProvider;
      } else {
        web3Provider = new Web3.providers.HttpProvider(config.RPC_URL);
      }
      web3 = new Web3(web3Provider);
      this.SET_STATE({
        type: "web3",
        data: web3
      });
      this.SET_STATE({
        type: "chainIdVal",
        data: this.web3.utils.numberToHex(await this.web3.eth.net.getId())
      });
      if (this.checkAndSwitchChainId()) return;
      this.SET_STATE({
        type: "account",
        data: window.ethereum.selectedAddress || null
      });
      this.web3.eth.getAccounts().then(accountRes => {
        if (accountRes[0]) {
          if (sessionStorage.getItem("mySession")) {
            if (this.isEnter) this.isLogin = true;
            this.SET_STATE({
              type: "isConnect",
              data: true
            });
            if (this.$route.query.code) {
              this.navIndex = 3;
            }
          } else {
            this.$router.push({
              query: { ...this.$route.query, Enter: "true" }
            });
          }
        }
      });
    },
    async switchChain() {
      if (window.ethereum) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: this.web3.utils.numberToHex(config.chainIdConfig)
              }
            ]
          });
        } catch (e) {
          if (e.code === 4902) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: this.web3.utils.numberToHex(config.chainIdConfig), //
                    chainName: config.chainName,
                    //nativeCurrency: {
                    //  name: config.chainName,
                    //  symbol: "ETH",
                    //  decimals: 18
                    //},
                    rpcUrls: [config.RPC_URL] //
                    //blockExplorerUrls: [ChainExploreUrl["https://etherscan.io"]]
                  }
                ]
              });
            } catch (ee) {
              //
            }
          } else if (e.code === 4001) return;
        }
      }
    },
    checkAndSwitchChainId() {
      let isStop = false;
      let nowId = this.chainIdVal
      if(String(nowId).indexOf('0x') == -1){
        nowId = this.web3.utils.numberToHex(nowId)
      }

      if (
        this.web3.utils.numberToHex(config.chainIdConfig) == nowId
      ) {
        this.tipChainError(false);
      } else {
        this.tipChainError(true);
        isStop = true;
      }

      return isStop;
    },
    tipChainError(boolean) {
      this.SET_STATE_MORE([
        { key: "tipShow", value: boolean },
        { key: "tipTitle", value: this.utils.tips.titleValError },
        { key: "tipSubtitle", value: this.utils.tips.tipschainId },
        { key: "btnText", value: this.utils.tips.btnText }
      ]);
    },
    getMyAccounts(callback) {
      if (this.checkAndSwitchChainId()) return;
      this.web3.eth
        .getAccounts()
        .then(account => {
          if (account[0]) {
            callback();
          } else {
            this.clearFun();
          }
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  }
};
</script>


<style lang="scss" scoped>
.homePage {
  &-container {
    width: 100%;
    //max-width: 1920px;
    @include bgImg;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    background-image: url("../assets/images/shop/bg.png");

    &-bg {
      width: 100%;
    }
  }
  &-TableBox {
    width: 58%;
    //max-width: 1100px;
    min-width: 785px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px;
    box-sizing: border-box;
    overflow: hidden;
    &-Header {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4%;
      &-logo {
        width: 34%;
        @extend .pointer;
        img {
          width: 100%;
        }
      }
      &-connectBox {
        width: 18%;
        position: relative;
        @extend .pointer;
        img {
          width: 100%;
        }
        &-connectText {
          @extend .pos-flex-center;
          color: #fff;
          font-size: px2rem(20);
          font-weight: bold;
        }
      }
    }
    &-table {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      position: relative;
      img {
        width: 100%;
      }
      &-leftNavBox {
        width: calc(24% - 5px);
        height: 100%;
        padding: 12px 0;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        &-navItem {
          width: 90%;
          margin: 0 auto;
          margin-bottom: px2rem(24);
          cursor: pointer;
          img {
            width: 100%;
          }
        }
      }
      &-rightContent {
        width: calc(78% - 23px);
        height: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        left: 24%;
      }
    }
  }
}
.dialogue {
  width: 11%;
  position: absolute;
  bottom: 2%;
  right: 5%;
  cursor: pointer;
}
.dialogue img {
  width: 100%;
}
.mobileNavBox {
  width: 100%;
  height: px2rem(240);
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}
@media screen and (max-width: 768px) {
  .homePage {
    &-TableBox {
      width: 94%;
      height: 80%;
      background-color: #000;
      min-width: auto;
      border-radius: 10px;
      position: fixed;
      top: calc(50% + 30px);
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 12px;
      box-sizing: border-box;
      overflow: hidden;
      &-table {
        width: 100%;
        height: 100%;
        position: relative;
        &-rightContent {
          width: 100%;
          height: 100%;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          position: absolute;
          left: 0;
        }
      }
    }
  }
}


</style>
