<template>
  <div class="accountLink-Box">
    <div class="accountLink-Header">
      <div class="blindBoxTitle">
        <div class="title_text">
          <div>[</div>ACCOUNT LINK
          <div>]</div>
        </div>
      </div>
      <div class="blindBoxDesc">Manage your TORI account.</div>
    </div>
    <img src="../assets/images/shop/shopImg/line.png" class="lineImg" alt />
    <div class="accountLink-content">
      <div class="accountLink-titleText">
        Connect Discord
        <span>{{username}}</span>
      </div>
      <div class="accountLink-imageBtn btnScale">
        <img
          src="../assets/images/shop/accountLink/concat.png"
          alt
          @click="connectBtn"
          v-if="bindtype == 1"
        />
      </div>
      <div class="accountLink-imageBtn btnScale">
        <img
          src="../assets/images/shop/accountLink/disconcat.png"
          alt
          @click="disconnectBtn"
          v-if="bindtype == 2"
        />
      </div>
      <div class="accountLink-titleBox">
        <div class="accountLink-titleText">Whitelist Address</div>
        <div class="accountLink-titleText">
          Reset To Default
          <img
            src="../assets/images/shop/accountLink/refresh.png"
            alt
            @click="clearFun()"
            class="btnScale"
          />
        </div>
      </div>
      <div class="accountLink-imageBtn">
        <!--<img src="../assets/images/shop/accountLink/concat.png" alt v-if="account" />-->
        <div class="accountLink-imageBtn-discordInput" v-if="account">
          <img src="../assets/images/shop/accountLink/concatActive.png" alt />
          <input type="text" name id v-model="updateAddress" />
        </div>
      </div>
      <div class="accountLink-imageBtn btnScale" @click="syncDiscordFun()">
        <img src="../assets/images/shop/accountLink/save.png" alt />
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapMutations } from "vuex";
import { Client } from "../common/index";
export default {
  data() {
    return {
      username: "",
      discordPortrait: null,
      updateAddress: "",
      bindtype: 1,
      nonce: "Sign this message to login account link.",
      isClick: false
    };
  },
  computed: {
    ...mapGetters(["web3", "account", "isConnect"])
  },
  watch: {
    account() {
      // this.account = this.$store.state.account;
      this.init();
    }
    // "$store.state.isConnect": function() {
    //   this.isConnect = this.$store.state.isConnect;
    // }
  },
  created() {
    // this.account = this.$store.state.account;
    // this.isConnect = this.$store.state.isConnect;
    this.init();
    this.updateAddress = this.account;
  },
  methods: {
    ...mapMutations(["SET_STATE", "SET_STATE_MORE"]),
    init: function() {
      if (!this.account) return;
      this.getUserInfo();
      if (this.$route.query.code) {
        this.getCode(this.$route.query.code);
      }
    },
    async initClient() {
      let clientId = "961564144757268550";
      let clientSecret = "k3GevJ_g6kkomYwj0ks7uaD7WM1aRibY";
      this.clientFun = new Client(clientId, clientSecret);
      let url = location.protocol + "//" + location.host;
      if (url.substring(url.length, url.length - 1) != "/") {
        url = url + "/";
      }
      this.clientFun.setRedirect(url);
      this.clientFun.setScopes(["identify"]);
    },
    getCode: function(code) {
      this.initClient();
      this.utils.post(
        "bindDiscord",
        {
          address: this.account,
          code: code,
          type: this.bindtype
        },
        res => {
          this.loadShow = true;
          let data = res.data;
          if (res.code == 0) {
            if (this.bindtype == 1) {
              // this.username = data.username + '#' + data.discriminator;
              this.username = data.username;
              this.bindtype = 2;
              this.SET_STATE_MORE([
                { key: "tipShow", value: true },
                { key: "tipTitle", value: this.utils.tips.titleValBindSuccess },
                { key: "tipSubtitle", value: this.utils.tips.BindSucceeded + this.username },
                { key: "btnText", value: this.utils.tips.btnText }
              ]);
            } else if (this.bindtype == 2) {
              this.username = "";
              this.bindtype = 1;
              this.isClick = false;
              this.SET_STATE_MORE([
                { key: "tipShow", value: true },
                { key: "tipTitle", value: this.utils.tips.titleValBindSuccess },
                { key: "tipSubtitle", value: this.utils.tips.UnboundSucceeded },
                { key: "btnText", value: this.utils.tips.btnText }
              ]);
            }
          } else if (res.code == 1001) {
            this.SET_STATE_MORE([
              { key: "isRestart", value: true },
              { key: "tipShow", value: true },
              { key: "tipTitle", value: this.utils.tips.titleValError },
              { key: "tipSubtitle", value: this.utils.tips.tips1001 },
              { key: "btnText", value: this.utils.tips.btnText }
            ]);
          } else if (res.code == 1006) {
            this.SET_STATE_MORE([
              { key: "tipShow", value: true },
              { key: "tipTitle", value: this.utils.tips.titleValError },
              { key: "tipSubtitle", value: this.utils.tips.tips1006 },
              { key: "btnText", value: this.utils.tips.btnText }
            ]);
          } else if (res.code == 1011) {
            this.SET_STATE_MORE([
              { key: "tipShow", value: true },
              { key: "tipTitle", value: this.utils.tips.titleValSorry },
              { key: "tipSubtitle", value: this.utils.tips.tips1011 },
              { key: "btnText", value: this.utils.tips.btnText }
            ]);
          }
        }
      );
      this.$router.push({ query: {} });
    },
    getUserInfo: function() {
      this.$emit("getMyAccounts", () => {
        this.utils.post(
          "getUserInfo",
          {
            address: this.account
          },
          res => {
            if (res.code == 0) {
              this.updateAddress = res.data.sendAddress;
              if (res.username) {
                this.bindtype = 2;
                this.username = res.username;
              }
            }
          }
        );
      });
    },
    connectBtn: function() {
      if(this.isClick) return;
      this.isClick = true;
      this.Nowtimestamp = Date.parse(new Date());
      const personalVal = this.nonce + this.Nowtimestamp;
      this.web3.eth.personal
        .sign(personalVal, this.account)
        .then(() => {
          this.initClient();
          window.location.href = this.clientFun.createAuthLink();
        });
    },
    disconnectBtn: function() {
      this.getCode("");
    },
    clearFun: function() {
      this.$emit("getMyAccounts", () => {
        if (!this.account) return;
        this.utils.post(
          "resetToDefault",
          {
            address: this.account
          },
          res => {
            if (res.code == 0) {
              this.getUserInfo();
              this.$router.push({ query: {} });
              this.SET_STATE_MORE([
                { key: "tipShow", value: true },
                { key: "tipTitle", value: this.utils.tips.titleValSuccess },
                { key: "tipSubtitle", value: this.utils.tips.restTip },
                { key: "btnText", value: this.utils.tips.btnText }
              ]);
            }
          }
        );
      });
    },
    syncDiscordFun: function() {
      this.$emit("getMyAccounts", () => {
        if (!this.account) return;
        if (!this.updateAddress) {
          this.SET_STATE_MORE([
            { key: "tipShow", value: true },
            { key: "tipTitle", value: this.utils.tips.titleValError },
            { key: "tipSubtitle", value: this.utils.tips.updateInputNull },
            { key: "btnText", value: this.utils.tips.btnText }
          ]);
          return false;
        }
        this.utils.post(
          "updateSendAddress",
          {
            address: this.account,
            sendAddress: this.updateAddress
          },
          res => {
            if (res.code == 0) {
              this.getUserInfo();
              let showAddress = '';
              if(res.data.length >= 5){
                showAddress = res.data.substring(0,5)+'...'+res.data.substring(res.data.length,res.data.length-4)
              }
              
              this.SET_STATE_MORE([
                { key: "tipShow", value: true },
                { key: "tipTitle", value: this.utils.tips.titleValBindSuccess },
                { key: "tipSubtitle", value: this.utils.tips.updateSuccess +  showAddress },
                { key: "btnText", value: this.utils.tips.btnText }
              ]);
            } else if (res.code == 1001) {
              this.SET_STATE_MORE([
                { key: "isRestart", value: true },
                { key: "tipShow", value: true },
                { key: "tipTitle", value: this.utils.tips.titleValError },
                { key: "tipSubtitle", value: this.utils.tips.tips1001 },
                { key: "btnText", value: this.utils.tips.btnText }
              ]);
            } else if (res.code == 1008) {
              this.SET_STATE_MORE([
                { key: "tipShow", value: true },
                { key: "tipTitle", value: this.utils.tips.titleValError },
                { key: "tipSubtitle", value: this.utils.tips.tips1008 },
                { key: "btnText", value: this.utils.tips.btnText }
              ]);
            }
          }
        );
      });
    }
  }
};
</script>


<style lang="scss" scoped>
.lineImg {
  width: 100%;
}
.accountLink {
  &-Box {
    width: 100%;
    height: 100%;
    padding: 2% 3%;
    box-sizing: border-box;
  }
  &-Header {
    width: 100%;
    margin-bottom: 2%;
    position: relative;
  }
  &-content {
    width: 100%;
    height: calc(100% - 90px);
    position: relative;
  }
  &-titleText {
    line-height: px2rem(30);
    font-size: px2rem(16);
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: px2rem(24);
    margin-bottom: px2rem(12);

    font-family: Bold;
    img {
      width: px2rem(20);
      margin-left: px2rem(20);
      cursor: pointer;
    }
    span {
      margin-left: 12px;
    }
  }
  &-titleBox {
    width: 60%;
    display: flex;
    justify-content: space-between;
  }
  &-imageBtn {
    width: 60%;
    cursor: pointer;
    margin-bottom: 1%;
    img {
      width: 100%;
    }
    &-discordInput {
      width: 100%;
      height: auto;
      position: relative;
      img {
        width: 100%;
      }
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        color: #fff;
        font-size: px2rem(16);
        padding: 0 4%;
        box-sizing: border-box;
      }
    }

    &myAddress {
      width: 100%;
      position: relative;
      img {
        width: 100%;
      }
      &-Text {
        color: #fff;
        text-align: center;
        font-size: 16px;
        @extend .pos-flex-center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .accountLink {
    &-Box {
      padding: 0;
    }
    &-content {
      padding-top: 6%;
      box-sizing: border-box;
    }
    &-titleText {
      width: 90%;
      margin: 0 auto;
      line-height: px2rems(30);
      font-size: px2rems(16);
      margin-top: px2rems(24);
      margin-bottom: px2rems(12);
      font-family: Bold;
      img {
      width: px2rems(20);
      margin-left: px2rems(20);
      }
    }
    &-titleBox {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 6%;
      margin-bottom: px2rems(6);
      div {
        justify-content: space-between;
      }
      :last-of-type {
        justify-content: flex-end;
      }
    }
    &-imageBtn {
      width: 90%;
      cursor: pointer;
      margin: 0 auto;
      margin-bottom: 6%;
      img {
        width: 100%;
      }
      &-discordInput {
        width: 100%;
        height: auto;
        position: relative;
        img {
          width: 100%;
        }
        input {
          font-size: px2rems(16);
        }
      }

      &myAddress {
        width: 100%;
        position: relative;
        img {
          width: 100%;
        }
        &-Text {
          font-size: px2rems(16);
        }
      }
    }
  }
}
</style>
