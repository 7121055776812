import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        web3: '',
        account: '',
        isConnect: false,
        discordAccount: '',
        navIndex: 0,

        tipShow: false,
        loadIngShow: false,
        tipTitle: '',
        tipSubtitle: '',
        btnText: '',
        chainIdVal: '',
        isRestart: false,
        tipFun: '',
        globalConfig: {}
    },

    mutations: {
        saveAccount(state, account) {
            state.account = account;
        },
        saveIsConnect(state, isConnect) {
            state.isConnect = isConnect;
        },
        saveweb3(state, web3) {
            state.web3 = web3;
        },
        savenavIndex(state, navIndex) {
            state.navIndex = navIndex
        },
        SET_STATE_MORE(state, data = []) {
            data.forEach(item => {
                state[item.key] = item.value
            })
        },
        SET_STATE(state, { type, data }) {
            state[type] = data
        },
    },

    getters: {
        web3: state => state.web3,
        account: state => state.account,
        discordAccount: state => state.discordAccount,
        isConnect: state => state.isConnect,
        navIndex: state => state.navIndex,
        tipShow: state => state.tipShow,
        loadIngShow: state => state.loadIngShow,
        tipTitle: state => state.tipTitle,
        tipSubtitle: state => state.tipSubtitle,
        btnText: state => state.btnText,
        chainIdVal: state => state.chainIdVal,
        isRestart: state => state.isRestart,
        tipFun: state => state.tipFun,
        globalConfig: state => state.globalConfig
    }
})
