<template>
  <div class="mobileNav">
    <div class="mobileNav-logoBox">
      <img src="../assets/images/header/logo.png" alt />
    </div>
    <div
      class="mobileNav-myAdress"
    >{{isConnect && account ?account.substring(0,5)+'...'+account.substring(account.length,account.length-4):''}}</div>
    <div class="mobileNav-dialogue" @click="goDialogue">
      <img src="../assets/images/header/dialogue.png" alt />
    </div>
    <div class="mobileNav-showNavBtn">
      <img
        src="../assets/images/header/mobile_nav/open.png"
        alt
        v-if="!isOpen"
        @click="isOpen = true"
      />
      <img
        src="../assets/images/header/mobile_nav/close.png"
        alt
        v-if="isOpen"
        @click="isOpen = false"
      />
    </div>

    <div class="navContainer" v-if="isOpen">
      <div class="navItem" v-for="(item,index) in nav" :key="index" @click="setNav(index)">
        <img :src="navIndex == index?item.imgActive:item.img" alt />
      </div>
      <div class="navItem">
        <a href="https://mirror.xyz/torizero-redlab.eth" target="_blank" rel="noopener noreferrer">
          <img src="../assets/images/header/mobile_nav/economy.png" alt />
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["web3", "account", "isConnect", "navIndex"])
  },
  data() {
    return {
      nav: [
        {
          img: require("../assets/images/header/mobile_nav/in.png"),
          imgActive: require("../assets/images/header/mobile_nav/inActive.png")
        },
        {
          img: require("../assets/images/header/mobile_nav/myBox.png"),
          imgActive: require("../assets/images/header/mobile_nav/myBoxActive.png")
        },
        {
          img: require("../assets/images/header/mobile_nav/market.png"),
          imgActive: require("../assets/images/header/mobile_nav/marketActive.png")
        },
        {
          img: require("../assets/images/header/mobile_nav/acLink.png"),
          imgActive: require("../assets/images/header/mobile_nav/acLinkActive.png")
        },
        // {
        //   img: require("../assets/images/header/mobile_nav/genesis.png"),
        //   imgActive: require("../assets/images/header/mobile_nav/genesisActive.png")
        // }
      ],
      isOpen: false
    };
  },
  methods: {
    goDialogue: function() {
      window.open(this.utils.dialogueUrl, "_blank");
    },
    setNav (index) {
      this.isOpen = false;
      this.$emit("setNowNav", index);
    }
  }
};
</script>


<style lang="scss" scoped>
.mobileNav {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 px2rems(10);
  box-sizing: border-box;
  &-logoBox {
    width: 45%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  &-myAdress {
    width: 26%;
    text-align: center;
    color: #fff;
    font-size: px2rems(20);
    font-family: bold;
  }
  &-dialogue {
    width: 6%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  &-showNavBtn {
    width: 6%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}
.navContainer {
  width: 100%;
  height: calc(100% - 60px);
  padding-top: px2rems(44);
  box-sizing: border-box;
  position: fixed;
  top: px2rem(240);
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
}
.navItem {
  width: 92%;
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: px2rems(44);
}
.navItem img {
  width: 100%;
}
</style>